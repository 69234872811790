import { ca } from "date-fns/locale";
import PMOServices from "../../Service/PMOServices";

const fetchNotifications = async (setNotifications) => {
  try {
    const payload = {
      userId: Number(window.localStorage.getItem("user_Id")),
    };
    const response = await PMOServices.getNotificationService(payload);
    console.log(response.data, "notificationResponse");
const filteredNotifications =  response?.data?.filter(
  (notification) => !notification.content.includes('\"')
);



    const mappedNotifications = filteredNotifications
      ?.map((notification) => {
        console.log(notification, "notification data");

        let mappedNotification = null;
        const content = notification.notificationsData;
        let title = `Project ${content?.project_name}`;
        let description = "";

        if (notification.entityTypeId === 2) { // Project Notifications
          const allocatedTo = `${content.pmo_name} ${content.pm_name} ${content.pc_name} and ${content.customer_name}`;
          const budget = content.budget;
          const timeline = `${content.start_date} - ${content.end_date}`;

          description = `Allocated to ${allocatedTo}`;
          mappedNotification = {
            title,
            description,
            budget: `Budget: ${budget}`,
            timeline: `Timeline: ${timeline}`,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: notification?.read,
          };

          console.log(mappedNotification, "mappedNotification");
        } else if (notification.entityTypeId === 3) { // Subtask Notifications
          switch (content._task_status_name) {
            case "In-progress Requested":
              description = `SubTask ${content._task_type_name} of milestone ${content._milestone_name} is in In-progress.`;
              break;
            case "In Progress":
              description = `In-progress of SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is Approved by ${content._actor_name}`;
              break;
            case "In Progress - Approved by PM":
              description = `In-progress Request of SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is Approved by ${content.pm_name}`;
              break;
            case "Completed Requested":
              description = `${content._task_status_name} by ${content.pc_name} for SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name}`;
              break;
            case "Completed":
              description = `SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is ${content._task_status_name}`;
              break;
            case "Completed - Approved by PM":
              description = `Completed Request of SubTask ${content._task_type_name} of milestone ${content._milestone_name} in Project ${content.project_name} is Approved by ${content.pm_name}`;
              break;
            default:
              description = "Unknown task status";
          }

          mappedNotification = {
            title,
            description,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: notification.read,
          };
        }else if(notification.entityTypeId === 6){
          if(content._task_action_status === "Ticket Created"){
            description = `Ticket is Created for Subtask ${content?.task_type_name} in Milestone ${content?.milestone_name} With  Impact of days-${content?.Impact_days}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          } else if (content._task_action_status === "Ticket Raised Approved") {
            description = `Ticket Raised for ${content.task_type_name} in ${content.milestone_name} has been Approved by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          } else if(content._task_action_status === "Inprogress Request"){
            description = `Ticket for ${content.task_type_name} in ${content.milestone_name} is in Inprogress.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          } else if(content._task_action_status === "Inprogress"){
            description = `Inprogress Ticket of ${content.task_type_name} in ${content.milestone_name}  has been Approved by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }else if(content._task_action_status === "Completed Requested"){
            description = `Ticket Completed Request by PC ${content.pc_name} for ${content.task_type_name} in ${content.milestone_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };   
          }else if(content._task_action_status === "Completed"){
            description = `Ticket for ${content.task_type_name} in ${content.milestone_name} has been marked as Completed by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }else if(content._task_action_status === "Ticket Raised Rejected"){
            description = `Ticket Raised for ${content.task_type_name} in ${content.milestone_name} has been Rejected by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }else if(content._task_action_status === "Ticket ReOpen"){
            description = `Ticket for ${content.task_type_name} in ${content.milestone_name} is reopened by PC ${content.pc_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }else if(content._task_action_status === "Ticket ReOpen Rejected"){
            description = ` Reopened Ticket for ${content.task_type_name} in ${content.milestone_name} has been Rejected by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }else if(content._task_action_status === "Ticket ReOpen Approved"){
            description = `Reopened Ticket for ${content.task_type_name} in ${content.milestone_name} has been Approved by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }else if(content._task_action_status === "Completed Requested Rejected"){
            description = `Completed Request for ${content.task_type_name} in ${content.milestone_name} has been Rejected by PM ${content.pm_name}.`;
            mappedNotification = {
              title,
              description,
              entityTypeId: content.entity_type_id,
              notificationId: content.notification_id,
              read: notification.read,
            };
          }
        } else if(notification.entityTypeId === 8) {
          description = `${content.action_content} a new drawing ${content.task_description} for ${content.task_type_name} in ${content.milestone_name}.`;
          mappedNotification = {
            title,
            description,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: notification?.read,
          };
        } else if(notification.entityTypeId === 9) {
          switch (content.action_content) {
            case "PM Approved":
              description =  `PM ${content.pm_name} has approved the drawing ${content.task_description} for ${content.task_type_name} in ${content.milestone_name}.`;
              break;
              case "Customer Approved":
                description =  `Customer ${content.customer_name} has approved the drawing ${content.task_description} for ${content.task_type_name} in ${content.milestone_name}.`;
                break;
                case "PM Rejected":
                  description =  `PM ${content.pm_name} has rejected the drawing ${content.task_description} for ${content.task_type_name} in ${content.milestone_name}.`;
                  break;
                  case "Customer Rejected":
                    description =  `Customer ${content.customer_name} has rejected the drawing ${content.task_description} for ${content.task_type_name} in ${content.milestone_name}.`;
                    break;
              default:
              description = "Unknown task status";
          }
          mappedNotification = {
            title,
            description,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: notification?.read,
          };
        } else if(notification.entityTypeId === 7) {
          console.log("in block request notification",notification);
         switch (content._task_action_status) {
            case "Block Requested":
              description = `${content.task_type_name} in ${content.milestone_name} has been ${content._task_action_status.toLowerCase()} by ${content.pc_name}.`;
              break;
            case "Blocked by PM":
              description = `${content.task_type_name} in ${content.milestone_name} has been ${content._task_action_status.toLowerCase()} ${content.pm_name}.`;
              break;
              case "Block Approved":
                description = `${content.task_type_name} in ${content.milestone_name} has been ${content._task_action_status.toLowerCase()} by ${content.pm_name}.`;
                break;
                case "Unblock Requested":
                  description = `${content.task_type_name} in ${content.milestone_name} has been ${content._task_action_status.toLowerCase()} by ${content.pc_name}.`;
                  break;
                case "Unblock Approved":
                    description = `${content.task_type_name} in ${content.milestone_name} has been ${content._task_action_status.toLowerCase()} by ${content.pm_name}.`;
                    break;
                    case "Unblocked by PM":
                      description = `${content.task_type_name} in ${content.milestone_name} has been ${content._task_action_status.toLowerCase()}  ${content.pm_name}.`;
                      break;
              default:
              description = "Unknown task status";
         }
         mappedNotification = {
          title,
          description,
          entityTypeId: content.entity_type_id,
          notificationId: content.notification_id,
          read: notification?.read,
         }
        } else if(notification.entityTypeId === 15) {
          switch (content.entity_type_name) {
             case "reallocate project to pm":
              description = `Project ${content.project_name} has been Reallocated to PM ${content._new_pm_name}.`;
              break;
              default:
              description = "Unknown task status";
          }
          mappedNotification = {
            title,
            description,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: notification?.read,
          }
          console.log(mappedNotification, "Reallocate mappedNotification");
        } else if(notification.entityTypeId === 10) {
          switch (content.entity_type_name) {
             case "reallocate project to pc":
              description = `Project ${content.project_name} has been Reallocated to PC ${content._new_pc_name}.`;
              break;
              default:
              description = "Unknown task status";
          }
          mappedNotification = {
            title,
            description,
            entityTypeId: content.entity_type_id,
            notificationId: content.notification_id,
            read: notification?.read,
          }
          console.log(mappedNotification, "Reallocate mappedNotification");
        } else if(notification.entityTypeId === 11){
          title = "Leads Upload";
          description = `${notification.content}`
          mappedNotification = {
            title,
            description,
            entityTypeId: notification.entityTypeId,
            notificationId: notification.notificationId,
            read: notification?.read,
          }
        } else if(notification.entityTypeId === 12){
          title = "Lead Allocation";
          description = `${notification.content}`
          mappedNotification = {
            title,
            description,
            entityTypeId: notification.entityTypeId,
            notificationId: notification.notificationId,
            read: notification?.read,
          }
        }else if(notification.entityTypeId === 13){
          title = "Lead ReAllocation";
          description = `${notification.content}`
          mappedNotification = {
            title,
            description,
            entityTypeId: notification.entityTypeId,
            notificationId: notification.notificationId,
            read: notification?.read,
          }
        }else if(notification.entityTypeId === 14){
          title = "Leads Status";
          description = `${notification.content}`
          mappedNotification = {
            title,
            description,
            entityTypeId: notification.entityTypeId,
            notificationId: notification.notificationId,
            read: notification?.read,
          }
        }
        return mappedNotification;

      })
      .filter((notification) => notification !== null)
      .reduce((acc, current) => {
        if (!acc.some((notif) => notif.notificationId === current.notificationId)) {
          acc.push(current);
        }
        return acc;
      },
       []);

    setNotifications(mappedNotifications);
  } catch (err) {
    console.error("Error fetching notifications:", err);
  }
};

export default fetchNotifications;
