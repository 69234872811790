import React, { useState, useRef, useEffect } from 'react';
import { useStyles } from './SendMessageBox.style';
import EmojiPicker from 'emoji-picker-react';
import {
  AttachIcon,
  EmojiIcon,
  MicroPhoneIcon,
  SendMessageIcon
} from '../../../Icons/icons.tsx';
// import { Button } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
import { useMutation } from '@apollo/client';
import { CREATE_MESSAGE } from '../../queries/createMessage.tsx';
// import { SuccessToast } from '../../../../../../LibraryComponents/Toaster/Toaster';
import { SendMessageBoxTypes } from './SenMessageBox.types.ts';
import { SEND_TYPING_NOTIFICATION } from '../../queries/senderTypingNotificationMutation.tsx';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import dotenv from 'dotenv';
// dotenv.config();
import { X } from 'lucide-react';

import chartDocImage from '../../../assets/chatDocImage.png';
import jsonImage from '../../../assets/chatJsonImage.png'
import chatPdfImage from '../../../assets/chatPdfImage.png';
import chatXlsImage from '../../../assets/chatXlsImage.png';
import { useUpdateMessageStatus } from '../../chatHooks/useUpdateMessageStatuss/index.tsx'; 
// interface SendMessageProps{
//     roomId:any;
//     userId:any;
// }
export interface FileItem {
  id: string;
  file: File;
  name: string;
}

const SendMessageBox: React.FC<SendMessageBoxTypes> = ({ roomId, userId, isExpandChat,userName,setIsTyping, currentUsersCountInRoom}) => {
  const { classes } = useStyles({ isExpandChat });
  const [message, setMessage] = useState('');

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [sendTypingNotification] = useMutation(SEND_TYPING_NOTIFICATION);
  // const typingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const typingIntervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isTypingActive, setTypingActive] = useState(false);
  // const [attachment, setAttachment] = useState<File | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isAttachClicked, setIsAttachedClicked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[] | any>([]);
  const [uploadFiles, setUploadFiles] = useState<any>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { updateMessageStatus} = useUpdateMessageStatus();
  //console.log("currentUsersCountInRoom from sendMessageBox =========> ", currentUsersCountInRoom)

  const handleMessageUpdate = async (messageId: string, roomId: string, userId: string) => {
    try {
      const result = await updateMessageStatus(
        roomId,
        userId,
        messageId
      );
      console.log('Status updated from sendmessageBox =======> :', result);
    } catch (err) {
      console.error('Error sendmessageBox =======> :', err);
    }
  };
  
  // const [fileUrls, setFileUrls] = useState<{[key: string]: string}>({});

  const s3Client = new S3Client({
    region: 'ap-south-1',
    credentials: {
      accessKeyId: "AKIA5KQIGMPYFLFZQ5ZS",
      secretAccessKey:"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"
    }
  });
  console.log('selectedFiles', selectedFiles);
  const fileTypeIcons: { [key: string]: string } = {
    json: jsonImage,
    doc: chartDocImage,
    pdf: chatPdfImage,
    xls: chatXlsImage
  };

  const handleInputMessage = async (e: any) => {
    setMessage(e.target.value);
    // console.log('sssssd',userName)
  };

  const startTyping = () => {
    if (!isTypingActive) {
      setTypingActive(true);
      typingIntervalRef.current = setInterval(async () => {
        try {
          await sendTypingNotification({
            variables: {
              roomId,
              userId: userName,
              isTyping: true
            }
          });
        } catch (err: any) {
          console.error('Error in typing mutation:', err.message);
        }
      }, 1000); // Trigger mutation every 1 second
    }
  };

  const stopTyping = async () => {
    if (typingIntervalRef.current) {
      clearInterval(typingIntervalRef.current);
      typingIntervalRef.current = null;
    }
    setTypingActive(false);
    try {
      await sendTypingNotification({
        variables: {
          roomId,
          userId: userName,
          isTyping: false // Indicate typing has stopped
        }
      });
      setIsTyping(false); // Update local state
    } catch (err: any) {
      console.error('Error in typing mutation on blur:', err.message);
    }
  };

  const getFileType = (fileName: string): string => {
    const ext = fileName.split('.').pop()?.toLowerCase() ?? '';
    const types = new Map([
      ['png', 'image/png'],
      ['jpg', 'image/jpeg'],
      ['jpeg', 'image/jpeg'],
      ['gif', 'image/gif'],
      ['pdf', 'application/pdf'],
      ['doc', 'application/msword'],
      ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      ['xls', 'application/vnd.ms-excel'],
      ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      ['json', 'application/json']
    ]);
    return types.get(ext) || 'application/octet-stream';
  };

  const uploadToS3 = async (
    files: { id: string; file: File; name: string }[]
  ): Promise<
    {
      extension: string;
      mime_type: string;
      name: string;
      size: string | number;
      url: string;
    }[]
  > => {
    try {
      const uploadedUrls: {
        extension: string;
        mime_type: string;
        name: string;
        size: string | number;
        url: string;
      }[] = [];

      for (const fileObj of files) {
        const file = fileObj.file; // Extract the File object
        const fileKey = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;

        console.log('Uploading file:', file.name, 'as', fileKey);
        const fileBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(fileBuffer);
 

        const response = await s3Client.send(
          new PutObjectCommand({
            Bucket: 'chat-media-messages1',
            Key: fileKey,
            Body: uint8Array, // Pass the File object
            ContentType: getFileType(file.name) // Use the MIME type from the File object
          })
        );
        const size = file.size || '';
        const mime_type = file.type || '';
        const name = file.name || '';
        const extension = name.split('.').pop() || '';
        const url = `https://chat-media-messages1.s3.ap-south-1.amazonaws.com/${fileKey}`;
        const fileData = { extension, mime_type, name, size, url };
        console.log('Upload response:', response);
        uploadedUrls.push(fileData);
        console.log('uploadedUrls', uploadedUrls);
      }
      return uploadedUrls;
    } catch (error) {
      console.error('S3 upload error:', error);
      throw error;
    }
  };

  const handleAction = async (event: React.MouseEvent | React.KeyboardEvent) => {
    if (!message.trim() && !selectedFiles) return;
    setIsTyping(false);
    let attachmentData:any;
    if (selectedFiles.length > 0) {
      try {
        attachmentData = await uploadToS3(selectedFiles);
        setSelectedFiles([]);
        setUploadFiles(attachmentData);
      } catch (error) {
        console.error('Error uploading to S3:', error);
        return;
      }
    }

    const formatAttachments = (attachments: any[] | null): string[] | null => {
      if (!attachments) return null;
      return attachments.map(attachment => JSON.stringify(attachment));
    };
    
    const input = {
      room_id: roomId,
      sender_id: userId,
      payload: message,
      current_users_count: currentUsersCountInRoom,
      message_type: selectedFiles.length > 0 ? 'file' : 'text',
      attachments:formatAttachments(attachmentData)
    };
    console.log("input",input);
    
    if (
      event.type === 'click' ||
      (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter')
    ) {
      stopTyping();
      try {
        const response = await createMessage({ variables: { input } });
        if (response) {
          console.log('Message Sent Successfully! from sendMessageBox =====> ',  response);
          handleMessageUpdate(response.data.createMessage.message_id, roomId, userId)
          // SuccessToast('Message Sent Successfully!');
        }
        setMessage('');
      } catch (err:any) {
        setMessage('');
        console.error('Error creating Message:', err.message);
      }
    }
  };
  const handleKeyDownInput = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && message.trim()) {
      handleAction(event);
    }
  };

  const addEmoji = (emojiData: any) => {
    const { emoji } = emojiData;
    setMessage((prevMessage) => prevMessage + emoji);
    setShowEmojiPicker(false);
    textAreaRef.current?.focus();
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const files = Array.from(e.target.files);
      setSelectedFiles((prev: any) => [
        ...prev,
        ...files.map((file) => ({
          id: Math.random().toString(36).substr(2, 9),
          file,
          name: file.name
        }))
      ]);
    }
  };

  const removeFile = (fileId: string) => {
    setSelectedFiles((files: any) => files.filter((f: any) => f.id !== fileId));
  };

  // const attachFiles = () => {
  //   setIsAttachedClicked((prev) => !prev);
  // };
  useEffect(() => {
    return () => {
      selectedFiles.forEach((fileObj: any) => {
        if (fileObj.file.type.includes('image')) {
          URL.revokeObjectURL(URL.createObjectURL(fileObj.file));
        }
      });
    };
  }, [selectedFiles]);
  return (
    <div className={classes.sendMessageBoxContainer}>
      <div className={classes.messageContainer}>
        {selectedFiles.length > 0 && (
          <div className={classes.filePreviewsContainer}>
            {selectedFiles.map((file: any) => (
              <div key={file.id} className={classes.filePreviewItem}>
                <div className={classes.filePreviewContent}>
                  <div className={classes.filePreviewThumb}>
                    {file.file.type.includes('image') ? (
                      <img
                        src={URL.createObjectURL(file.file)}
                        alt={file.name}
                        className={classes.imagePreview}
                      />
                    ) : (
                      <div className={classes.filePreviewThumb}>
                        <img
                          src={fileTypeIcons[file.name.split('.').pop().toLowerCase()]}
                          alt={file.name.split('.').pop().toUpperCase()}
                          style={{ maxWidth: '30px', maxHeight: '50px' }}
                        />
                      </div>
                    )}
                  </div>
                  <span className={classes.fileName}>{file.name}</span>
                </div>
                <button onClick={() => removeFile(file.id)} className={classes.removeButton}>
                  <X className={classes.removeIcon} />
                </button>
              </div>
            ))}
          </div>
        )}

        <textarea
          placeholder="Write a message..."
          className={classes.sendMessageInput}
          onChange={handleInputMessage}
          onKeyDown={handleKeyDownInput}
          value={message}
          onBlur={stopTyping}
          onClick={startTyping}
          ref={textAreaRef}
   
        />
      </div>
      <div className={classes.iconFlexContainer}>
        <div className={classes.iconContainer}>
          <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
            <AttachIcon />
            <input
              type="file"
              id="file-upload"
              multiple
              accept=".pdf,.doc,.docx,image/*,.json,.xls,.mp4"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </label>
        </div>
        <div className={classes.subIconFlexContainer}>
          <div className={classes.iconContainer} onClick={toggleEmojiPicker}>
            <EmojiIcon />
          </div>
          {showEmojiPicker && (
            <div className={classes.emojiPickerContainer}>
              <EmojiPicker onEmojiClick={addEmoji} height={isExpandChat ? 450 : 350} width={300} />
            </div>
          )}
          <div className={classes.iconContainer} style={{ cursor: 'not-allowed' }}>
            <MicroPhoneIcon />
          </div>
          <div
            className={`${classes.sendMessageButton} ${!message.trim() ? classes.disabledButton : ''}`}
            onClick={!message.trim() ? undefined : handleAction}
            onKeyDown={(e) => !message.trim() ? e.preventDefault() : handleAction(e)}
            tabIndex={!message.trim() ? -1 : 0}
            role="button"
            aria-disabled={!message.trim()}
          >
            <p className={classes.sendText}>Send</p>
            <SendMessageIcon className={classes.sendIcon} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default SendMessageBox;
