import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isRoomDescriptionClicked: any }>()(
  (
    _
    // isRoomDescriptionClicked
  ) => ({
    expandMainFullScreenContainer: {
      position: 'fixed', // Ensures it stays visible
      left: 0,
      backgroundColor: '#fff',
      width: '100vw',
      zIndex: 999,
      display: 'flex',
      minHeight: '100vh', // Ensures visibility on smaller screens
    },
    emptyStateContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#fafafa',
      alignItems: 'center',
      flexGrow: 1,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#555',
      width: '100%',
      minHeight: '100vh', // Ensures it doesn't collapse
    }
  })
);
