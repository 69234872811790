import instanceAll from '../../CommonAxiosInstance';

const getLeadsCountService= async ()=>{
    try{
      const response = await instanceAll.post("/get-dashboard-raw-leads");
      console.log("getLeadsList:", response);
      if(response.status === 200){
            return {
                status:"success",
                data:response.data
            }
      }else{
           return {
               status:"failed",
               data:response.data
           }
      }
    }catch(error){
        console.log("error:",error);
    }
}

// get leads and appointments by date range filter:
const getleadsandAppointmentsByDateFilter = async(body)=>{ 
    try{
       
     const response = await instanceAll.post("/get-crm-dashboard-leads-by-date-filter",body);
     console.log("getleadsandAppointmentsByDateFilter response :::",response);
     if(response.status === 200){
         return ({
             status:"success",
             data:response.data
         })
     }else{
         return({
             status:"failed",
             data:response.data
         })
     }
 
    }catch(error){
        return{
            status:-1,
            message:error
        }
    }
 }

 // get daily leads date range filter:
const getDailyLeadsDateFilter = async(body)=>{
    try{
       
     const response = await instanceAll.post("/get-crm-dashboard-daily-leads-trend-shot",body);
     console.log("getDailyLeadsDateFilter response :::",response);
     if(response.status === 200){
         return ({
             status:"success",
             data:response.data
         })
     }else{
         return({
             status:"failed",
             data:response.data
         })
     }
 
    }catch(error){
        return{
            status:-1,
            message:error
        }
    }
 }

 const getSalesExecutiveList= async (body)=>{
    try {
        
        const response  = await instanceAll.post('/get-salesExecutive-list',body);
         console.log("getSalesExecutiveList response:",response);
         if(response.status === 200){
          return ({
            status :'success',
            data:response.data
          })  
         }else{
            return({
                status : 'failed',
                data : response.data
            })
         }

    } catch (error) {
         return{
            status:-1,
            message:error
         }
    }
 }

 const getSoDashboardData= async (body)=>{
    try {
        
        const response  = await instanceAll.post('/get-so-dashboard-data',body);
         console.log("getSalesOfficeDashboard response:",response);
         if(response.status === 200){
          return ({
            status :'success',
            data:response.data
          })  
         }else{
            return({
                status : 'failed',
                data : response.data
            })
         }

    } catch (error) {
         return{
            status:-1,
            message:error
         }
    }
 }
 const getScDashboardData= async (body)=>{
    try {
        
        const response  = await instanceAll.post('/get-sc-dashboard-data',body);
         console.log("getSalesCoordinatorDashboard response:",response);
         if(response.status === 200){
          return ({
            status :'success',
            data:response.data
          })  
         }else{
            return({
                status : 'failed',
                data : response.data
            })
         }

    } catch (error) {
         return{
            status:-1,
            message:error
         }
    }
 }
 const getSeDashboardData= async (body)=>{
    try {
        
        const response  = await instanceAll.post('/get-se-dashboard-data',body);
         console.log("getSalesCoordinatorDashboard response:",response);
         if(response.status === 200){
          return ({
            status :'success',
            data:response.data
          })  
         }else{
            return({
                status : 'failed',
                data : response.data
            })
         }

    } catch (error) {
         return{
            status:-1,
            message:error
         }
    }
 }
export const salesCoordinatorDashboardServices={
    getLeadsCountService,
    getleadsandAppointmentsByDateFilter,
    getDailyLeadsDateFilter,
    getSalesExecutiveList,
    getSoDashboardData,
    getScDashboardData,
    getSeDashboardData
}