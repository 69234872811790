
import React, { useState, useEffect } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement } from "chart.js";
import './dashboard.css';
import { Pie, Bar, Doughnut } from "react-chartjs-2";
import D3Funnel from 'd3-funnel';
import * as d3 from "d3";
import { AiFillProfile,AiFillPhone, AiFillCalendar } from "react-icons/ai";
import { salesCoordinatorDashboardServices } from '../../../../Service/Dashboard/SalesCoordinator/getDashboardLeadsCount';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants'
import AppointmentsDetails from '../MyAppointments/Appointments'
import DatePicker from '../../../../Components/CommonComponents/dateRangePicker';
import { myLeadsAppointments } from '../../../../Service/MyLeadsService';
import { Popover } from 'antd';

import ActivityLog from './ActivityLog';
import { addDays, subDays } from 'date-fns';
import moment from 'moment';
import { faLoveseat } from '@fortawesome/pro-duotone-svg-icons';
import AdvancedFilter from './advancedFilter2';



const Dashboard = () => {
  const [leadsList, setLeadsList] = useState([]);
  const [leadsCount, setLeadsListCount] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [sourcesList, setSourcesList] = useState([]);
    const [scheduleList, setScheduleList] = useState([]);
    const [sourceLogos, setSourceLogos] = useState([]);
    const [serviceAnalysis, setServiceAnalysis] = useState([]);
  const [funnelLeadsList, setFunnelLeadsList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [funnel, setFunnel] = useState([]);
  const [selectDate, setSelectDate] = useState(false);
  const [selectRange, setSelectRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    }
  ])
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [select, setSelect] = useState('CrmDashboard');
  const [dailyLeads, setDailyLeads] = useState([]);
  const [leadsByWise, setLeadsByWise] = useState("");
  const [check, setCheck] = useState('crm');
  const [salesExecutivesList, setSalesExecutivesList] = useState([]);
  // const [salesExecutiveId, setSalesExecutiveId] = useState(null);
  const [showModal, setShowModal] = useState(false)
  // select calendar Function:
  const dateToggleHandler = () => {
    setSelectDate(!selectDate)
    setCheck('date')
  }
 const [advancedFilterData, setAdvancedFilterData] = useState({
   salesExecutive :null
 })

  // const startDate = selectRange[0].startDate.toString().slice(4, 15)
  // const endDate = selectRange[0].endDate.toString().slice(4, 15)

  const fromDate = selectRange[0].startDate ? moment(selectRange[0].startDate).format("YYYY-MMM-DD") : null;
    const fromDateCal = selectRange[0].startDate ? moment(selectRange[0].startDate).format("DD MMM YYYY") : null;
    const toDate = selectRange[0].endDate ? moment(selectRange[0].endDate).format("YYYY-MMM-DD") : null;
    const toDateCal = selectRange[0].endDate ? moment(selectRange[0].endDate).format("DD MMM YYYY") : null;
    
  console.log("selectDate:", fromDate);



  ChartJS.register(ArcElement, Tooltip, Legend, BarElement, ChartDataLabels);

  const Piedata = {
    labels: Object.keys(leadsList),
    datasets: [
      {
        label: "Something",
        data: Object.values(leadsList),
        backgroundColor: [
          "#fd2776",
          "#00deff",
          "#777777",
          "#1ad99f",
          "#559c11",
          "#5a87ff",
          "#ff3e00",
          "#33d0a0",
        ],
        borderColor: [
          "#fd2776",
          "#1ad99f",
          "#777777",
          "#1ad99f",
          "#559c11",
          "#5a87ff",
          "#ff3e00",
          "#33d0a0",
        ],
        borderWidth: 1
      }
    ]
  }



  //  console.log("funnel data :",FunnelData);

  /*   Apply Filter Function: */
  const applyFilter = () => {
    getSalesExecutiveDashboardData();
    setSelectDate(false)
    setCheck("filterBtn")

  }

  const settingsHandler = () => {
    setCheck('setting')
  }

  const dateDifferenceInDays = () => {
    const date1 = new Date(fromDate)
    const date2 = new Date(toDate)
    return Math.floor(
      (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
        Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
      (1000 * 60 * 60 * 24)
    )
  }


  //Logos and services data

  let sourceLogoArr = Object.values(sourceLogos).map(logo => logo.source_logo)

  let seBarServiceLabels = serviceAnalysis ? Object.keys(serviceAnalysis) : []
  let seBarServiceValues = serviceAnalysis ? Object.values(serviceAnalysis) : []
  let seDonutSourceValues = sourcesList ? Object.values(sourcesList) : []
  console.log("Donut values: " + seDonutSourceValues);
  console.log("Logo Array values: " + sourceLogoArr);
  

  const seBarData = {
    labels: seBarServiceLabels,
    datasets: [
      {
        data: seBarServiceValues,
        backgroundColor: [
          "#1877F2", // res
          ["#038B20"], // comm
          "#FBBC05", // arch
          "#F29D9D", // intr
          "#A2A4AF", // plus4
          "#9CB8B6", // plus5
          "#C6C482"  // others
        ],  // Light greenish blue
        hoverBackgroundColor: [
          "#1877F2", // res
          ["#038B20"], // comm
          "#FBBC05", // arch
          "#F29D9D", // intr
          "#A2A4AF", // plus4
          "#9CB8B6", // plus5
          "#C6C482"  // others
        ],
        // borderColor: "#eda949", // Darker greenish blue
        borderWidth: 1,
        setPercentage: [10, 2, 20, 40, 4, 6, 18]
        
      },
    ],
  };

  const seBarOptions = {
    responsive: true,
    plugins: {
      legend: { display: false, position: "top" },
      title: { display: true, text: "Services Analysis"  ,color: "#000", font: { size: 14}},
      datalabels: {
        anchor: 'end',    // Position on top of the bar
        align: 'top',     // Align text above bars
        formatter: (value) => value, // Display actual values
        font: {
          weight: 'bold',
          size: 14,
        },
        color: '#868789', // Customize text color
      },
    },
    scales: {
      x: { grid: { display: false }, barThickness: 1 , barPercentage: 0.6, categoryPercentage:0.8},
      y: { beginAtZero: true , barThickness: 10
        // ticks: { color: "#ffffff", font: { size: 14 } },
        // grid: { color: "rgba(255, 255, 255, 0.2)" },
      },
      
    },
    barThickness: 55,
    layout: {
    padding: {
      right: 50, // Adds space between the chart and the legend
    },
  },
  };

  const seDonutData = {
    labels: ["Meta", "Google", "Walk-in", "Website"],
    datasets: [
      {
        data: seDonutSourceValues, // Adjust percentages as needed
        backgroundColor: ["#4267B2", "#EA4335", "#34A853", "#FBBC05"], // Meta (Blue), Google (Green), Website (Yellow)
        hoverBackgroundColor: ["#385898","#F29D9D",  "#2A8C4E", "#E5A800"], // Slightly darker hover effect
        borderWidth: 2,
      },
    ],
  };

  const seDonutOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 14,
          },
          color: "#333",
          generateLabels: (chart) => {
            const datasets = chart.data.datasets[0];
            const total = datasets.data.reduce((acc, value) => acc + value, 0);
  
            return chart.data.labels.map((label, index) => {
              const value = datasets.data[index];
              const percentage = total > 0 ? ((value / total) * 100).toFixed(1) : 0;
              const color = datasets.backgroundColor[index];
  
              return {
                text: `${label} ${percentage} %`, // Colored percentage
                fillStyle: color,
                hidden: !chart.getDataVisibility(index),
                lineWidth: 0,
                strokeStyle: "#fff",
                pointStyle: "circle",
              };
            });
          },
        },
      },
      title: { display: true, text: "Source Analysis", color: "#000", font: { size: 14}},
      datalabels: {display:false},

      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const total = tooltipItem.dataset.data.reduce((acc, value) => acc + value, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(1);
            return `${tooltipItem.label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    layout: {
      padding: {
        right: 50,
      },
    },
    cutout: "60%",
    scales: {
      x: { display: false },
      y: { display: false },
    },
    responsive: true,
  };

  const sePieData = {
    labels: ['Cold', 'Hot', 'Won'],
    datasets: [
      {
        data: [leadsList['cold'], leadsList['hot'], leadsList['won']],
        backgroundColor: [
          '#4b7be5',
          '#fbbc05',
          '#ff5967',
          'rgba(75, 192, 192)',
          'rgba(153, 102, 255)',
          'rgba(255, 159, 64)',
        ],
        borderColor: [
          '#4b7be5',
          '#fbbc05',
          '#ff5967',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const sePieOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 14,
          },
          color: "#333",
          generateLabels: (chart) => {
            const datasets = chart.data.datasets[0];
            const total = datasets.data.reduce((acc, value) => acc + value, 0);
  
            return chart.data.labels.map((label, index) => {
              const value = datasets.data[index] > 0 ? datasets.data[index] : 0;
              const percentage = total > 0 ? ((value / total) * 100).toFixed(1) : 0;
              const color = datasets.backgroundColor[index];
  
              return {
                text: `${label} - ${value}`, // Colored percentage
                fillStyle: color,
                hidden: !chart.getDataVisibility(index),
                lineWidth: 0,
                strokeStyle: "#fff",
                pointStyle: "circle",
              };
            });
          },
        },
      },
      title: { display: true, text: "Leads Status", color: "#000", font: { size: 14}},
      datalabels: {display:false},

      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const total = tooltipItem.dataset.data.reduce((acc, value) => acc + value, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(1);
            return `${tooltipItem.label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    layout: {
      padding: {
        right: 50,
      },
    },
    // cutout: "60%",
    scales: {
      x: { display: false },
      y: { display: false },
    },
    responsive: true,
  };

  /*   get leads and appointments list APi : */
  const getSalesExecutiveDashboardData = async (filter) => {
     
    if (select === "CrmDashboard") {

      let data = {
        fromDate: fromDate,
        toDate: toDate,
        userId:advancedFilterData.salesExecutive || userId 
      }

      const leadsResponse = await salesCoordinatorDashboardServices.getSeDashboardData(data);
      console.log("getSalesExecutiveDashboardData:", leadsResponse.data.dashboardData[0].get_sales_executive_dashboard);
    
            if (leadsResponse.status === "success" && leadsResponse.data.dashboardData) {
               filter && setShowModal(false)
               setLeadsList(leadsResponse.data.dashboardData[0].get_sales_executive_dashboard.all_leads);
               setServicesList(leadsResponse.data.dashboardData[0].get_sales_executive_dashboard.service_counts);
               setServiceAnalysis(leadsResponse.data.dashboardData[0].get_sales_executive_dashboard.service_counts);
               setSourcesList(leadsResponse.data.dashboardData[0].get_sales_executive_dashboard.source_counts);
               setScheduleList(leadsResponse.data.dashboardData[0].get_sales_executive_dashboard.schedules_counts);
               setSourceLogos(leadsResponse.data.dashboardData[0].get_sales_executive_dashboard.source_logos);
              // const labels = leadsResponse.data.leadsDashboardFunnelData[0] ? Object.keys(leadsResponse.data.leadsDashboardFunnelData[0]) : "";
              // // console.log("labels:", labels);
      
              // const values = leadsResponse.data.leadsDashboardFunnelData[0] ? Object.values(leadsResponse.data.leadsDashboardFunnelData[0]) : 0;
              // // console.log("values:", values);
      
              // const data = labels?.map((item, index) => {
              //   return (
              //     {
              //       label: item,
              //       value: values[index]
              //     }
              //   )
              // });
      
              // console.log("data", data);
              // setLeadsData(data)
            }
          } else if (select === "ActivityLog") {
      
            let dateranges = {
              fromDate: fromDate,
              toDate: toDate,
              userId:advancedFilterData.salesExecutive  || userId 
            }
      
            const response = await salesCoordinatorDashboardServices.getDailyLeadsDateFilter(dateranges);
            console.log("daily leads response ::", response);
            if (response.status === 'success' && response.data.isDailyLeadsData) {
              filter && setShowModal(false)
              if (dateDifferenceInDays() > 31) {
                setDailyLeads(response.data.leadsByContinuousDateByYearly)
                setLeadsByWise('yearly')
              } else {
                setDailyLeads(response.data.leadsByContinuousDate)
                setLeadsByWise('daily')
              }
      
              const leadsResponse = await salesCoordinatorDashboardServices.getSeDashboardData(dateranges);
              console.log("getSalesExecutiveDashboardData:", leadsResponse);
              if (leadsResponse.status === "success" && leadsResponse.data.getLeadsDate) {
                setLeadsList(leadsResponse.all_leads);
      
              }
            } else {
              setDailyLeads([])
            }
      
          }

  }

  // console.log("leadsList:", leadsList);
  // console.log("leadsData::", leadsData);
  // console.log("dailyLeads::", dailyLeads);

  const userId = Number(window.localStorage.getItem("user_Id"))

  // function for select crm dashboard or activity log:
  const selectHandler = (e) => {
    setSelect(e.target.value)
    setCheck('crm')
  }


  const seSelectHandler = (value) => {
    console.log(`selected ${value}`);     
};

  const getSalesExecutivesList = async  ()=>{
        try {
          let data = {
            userId : Number(window.localStorage.getItem('user_Id'))
          }
          const response = await salesCoordinatorDashboardServices.getSalesExecutiveList(data);
          console.log(response);
          if(response?.status === 'success' && response.data.isSalesexecutivesList){
            setSalesExecutivesList(response.data.salesExecutivesList)
          }else{
            setSalesExecutivesList([])
          }
           

        } catch (error) {
          console.log(error);
        }
  }
console.log('salesExecutivesList:',salesExecutivesList.length);
console.log('salesExecutive Id:',advancedFilterData.salesExecutive);


const resetFilter=()=>{
  setAdvancedFilterData(data => ({...data , salesExecutive : null}))
  setShowModal(false)
} 


const modalHandler=(newOpen)=>{
  setShowModal(newOpen)
}

  useEffect(() => { 
      getSalesExecutiveDashboardData();
      getSalesExecutivesList();
   
    // getScheduleMeetings()
  }, [select,showModal])
  console.log('select::', select);

  return (
    <div>
      <div className="mainContent">
        <div className="contentHeader">
          <span className="headerTitle">Sales Executive Dashboard</span>
        </div>
        <div className="whContentBlock">
          <div className="bodyHeader dashboardHeader">
            
            <div className='groupBtns'>
              <button className={`btn dateSelector dashboradDateSelector ${check === "date" ? "ActiveBtn" : ""}`}>
                <input type="button"
                  value={(fromDate===null && toDate===null) ? "Select Date Range": (fromDateCal + "-" + toDateCal)}
                  className="date-selector"
                  onClick={dateToggleHandler}
                />
                <img src={imageAssets.calendar} onClick={dateToggleHandler} className="calendarIcon" />
                <div className="calendar">
                  {selectDate && <DatePicker date={selectRange} handler={setSelectRange} />}
                </div>
              </button>
              <button className={`btn applyFilterBtn`} onClick={applyFilter} >Apply Filter</button>
            </div>
          </div>

          <div className="seDashboardWrapper">  
                                        <div className="row">
                                          
                                          <div className="col mb-2">
                                            <div className="smCard"  style={{height:"180px"}}>
                                              <div className="cardItem">
                                                <div className="cardHead">
                                                  <span className="cardTopTitle">Leads</span>
                                                </div>
                                                <div className="cardStatusInfo">
                                                  <div><div><span>{leadsList['total_leads']? leadsList['total_leads'] : 0}</span><span>All</span></div></div>
                                                  <div><div><span>{leadsList['hot']? leadsList['hot'] :0}</span><span>Hot</span></div></div>
                                                  <div><div><span>{leadsList['cold']? leadsList['cold'] :0}</span><span>Cold</span></div></div>
                                                  <div><div><span>{leadsList['future']? leadsList['future'] :0}</span><span>Future</span></div></div>
                                                  <div><div><span>{leadsList['lost']? leadsList['lost'] :0}</span><span>Lost</span></div></div>
                                                  <div><div><span>{leadsList['won']? leadsList['won'] :0}</span><span>Won</span></div></div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
          
                                          <div className="col mb-2">
                                            <div className="smCard"  style={{height:"180px"}}>
                                              <div className="cardItem">
                                                <div className="cardHead">
                                                  <span className="cardTopTitle">Allocation</span>
                                                </div>
                                                <div className="cardStatusInfo">
                                                <div><div><span>{leadsList['potential']? leadsList['potential'] : 0}</span><span>Potential</span></div></div>
                                                <div><div><span>{leadsList['Discarded/Deleted']? leadsList['Discarded/Deleted'] : 0}</span><span>Discarded/Deleted  </span></div></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col mb-2">
                                            <div className="smCard"  style={{height:"180px"}}>
                                              <div className="cardItem">
                                                <div className="cardHead">
                                                  <span className="cardTopTitle">Services</span>
                                                </div>
                                                <div className="cardStatusInfo">
                                                  <div>
                                                    <div>
                                                    <span >{servicesList['Residential']? servicesList['Residential'] : 0}</span><span>Residential</span></div>
                                                    </div>
                                                
                                                  <div>
                                                  <div>
                                                    <span >{servicesList['Commercial']? servicesList['Commercial'] : 0}</span><span >Commercial</span></div>
                                                    </div>
                                                  <div  >
                                                  <div >
                                                    <span>{servicesList['Architecture']? servicesList['Architecture'] : 0}</span><span>Architecture</span>
                                                </div>
                                                  </div>
                                                  
                                                  <div>
                                                  <div>
                                                    <span>{servicesList['Interior']? servicesList['Interior'] : 0}</span><span>Interiors</span>
                                                    </div>
                                                    </div>
                                                  
                                                    {/* <div>
                                                    <div>
                                                    <span>{projectTypeData.Others ? projectTypeData.Others: 0}</span><span>Others</span></div>
                                                    </div> */}
                                                
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col mb-2">
                                            <div className="smCard" style={{height:"180px"}}>
                                              <div className="cardItem">
                                                <div className="cardHead">
                                                  <span className="cardTopTitle">Sources</span>
                                                </div>
                                                <div className="cardStatusInfo">
                              
                                                <div>
                                                  <div>
                                                  <span>{sourcesList['Meta']? sourcesList['Meta'] : 0}</span><span><img src={sourceLogoArr[3]} height={30} style={{ borderRadius: "4px", background:"#fff"}}/></span></div>
                                                  </div>
                                                  <div>
                                                  <div><span>{sourcesList['Google']? sourcesList['Google'] : 0}</span><span><img src={sourceLogoArr[0]} height={30} style={{ borderRadius: "4px", background:"#fff"}}/></span></div>
                                                  </div>
                                            
                                                  <div>
                                                  <div><span>{sourcesList['Wehouse Website']? sourcesList['Wehouse Website'] : 0}</span><span><img src={sourceLogoArr[2]} height={30} style={{ borderRadius: "4px", background:"#fff"}}/></span></div>
                                                  </div>
          
                                                  <div>
                                                  <div><span>{sourcesList['Wehouse']? sourcesList['Wehouse'] : 0}</span><span><img src={sourceLogoArr[1]} height={30} style={{ borderRadius: "4px", background:"#fff"}}/></span></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col mb-2">
                                            <div className="smCard" style={{height:"180px"}}>
                                              <div className="cardItem">
                                                <div className="cardHead">
                                                  <span className="cardTopTitle">Schedules</span>
                                                </div>
                                                <div className="cardStatusInfo">
                              
                                                <div>
                                                  <div>
                                                  <span>{scheduleList['my_task_count']? scheduleList['my_task_count'] : 0}</span><span><AiFillProfile style={{ fontSize: '28px', color: '#fff', borderRadius:"5px" }}/></span></div>
                                                  </div>
                                                  <div>
                                                  <div><span>{scheduleList['my_calls_count']? scheduleList['my_calls_count'] : 0}</span><span><AiFillPhone style={{ fontSize: '28px', color: '#fff', borderRadius:"5px"  }}/></span></div>
                                                  </div>
                                            
                                                  <div>
                                                  <div><span>{scheduleList['my_appointments_count']? scheduleList['my_appointments_count'] : 0}</span><span><AiFillCalendar style={{ fontSize: '28px', color: '#fff', borderRadius:"5px"  }}/></span></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        
                                        <div className="over-view-layout">
                                          <div className="row d-flex align-items-center justify-content-center">
                                            <div className="col-6 justify-content-center">
                                              <div className="dashboardCard">
                                                <div style={{
                                              width: "450px",
                                              height: "450px",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              gap: "30px", // Space between chart and legend
                                            }}
                                            className='mt-4 text-center m-auto'>
                                                <Pie data={sePieData} options={sePieOptions} />
                                                </div>
                                              </div>
                                            </div>
                                            {/* Doughnut Chart */}
                                           <div className="col-6 justify-content-center" >
                                            <div className="dashboardCard">
                                    
                                            <div 
                                            // style={{
                                            //   width:"500px",
                                            //    height:"400px",
                                            //   //  marginLeft:"20px",
                                            //   //  display:"flex",
                                            //   //  flexDirection:"column",
                                            //   //  alignItems:"center",
                                            //   //  justifyContent:"center",
                                            //   }} 
                                            style={{
                                              width: "450px",
                                              height: "450px",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              gap: "30px", // Space between chart and legend
                                            }}
                                            className='mt-4 text-center m-auto'>
                                              <Doughnut
                                              // height={550}
                                            
                                                data={seDonutData}
                                                options={seDonutOptions}
                                                // style={{ display: "block", margin: "0 auto"}}
                                              />
                                            </div>
                                            
                                          </div>
                                        </div>
                                          </div>
                                        </div>
                                        
                    </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;