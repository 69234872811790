import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import showpswdimg from '../Assets/images/show-pswd.svg';
import hidepswdimg from '../Assets/images/hide-pswd.svg';
import { emailSignupService } from '../Service/SignupServices';
import { mobileSignupService } from '../Service/SignupServices';
import { useDispatch, useSelector } from 'react-redux';
import { loginLoader,clearLoginError } from '../Features/Slices/LoginSlice';
import { imageAssets } from '../Assets/AssetsConstants/AssetsConstants';
import { useLocation } from 'react-router-dom';
import { GetMenuService } from '../Service/GetMenuService';
import {  createContext } from 'react';
import ProjectDetails from '../Pages/PMO/PMOProjects/PMOProjectDetails/ProjectDetails';

// Password Encryption :
const md5 = require('md5');

export const userIdContext = createContext();

export default function Signin() {
    const [user, setUser] = useState({
        email: "",
        phone: "",
        password: ""
    })
    const { logindata, message } = useSelector((state) => state.Login);
    console.log("login changes", logindata);
    const history = useHistory();
    const location = useLocation()
    const [value, setValue] = useState("");
    const [isRevealpswd, setisRevealpswd] = useState(false);
    const [checkVar, setCheckVar] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [userId, setUserId] = useState(null);
    const [check, setCheck] = useState("email")
    const dispatch = useDispatch();
    const [menus, setMenus] = useState(.0)

    useEffect(() => {
        redirectToLanding()
    }, [menus])
    const getMenuList = async (uId) => {
        try {
            const menuResponse = await GetMenuService({ userid: uId });
            console.log("menuResponse--", menuResponse);
            if (menuResponse && menuResponse.menuData.get_menu && menuResponse.menuData.status === 200) {
                console.log("iff-inside---");
                setMenus(menuResponse.menuData.Menu)
            }

        } catch (err) {
            console.log("err---", err);
        }
    }
    console.log("menus--", menus);

    /* Based on role land on Dashboard page  */
    const redirectToLanding = () => {
        if (menus?.length > 0) {

            for (let i = 0; i < menus.length; i++) {

                if (menus[i].Menu_category_Label === "Partner Executive") {
                    console.log("inside-p-landing---");
                    history.replace({ pathname: "/landing/partnerexecutive/dashboard" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "Sales Executive") {
                    console.log("inside-se-landing---");
                    history.replace({ pathname: "/landing/salesexecutive/dashboard" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "Admin") {         //need feedback
                    console.log("inside-ad-landing---");
                    history.replace({ pathname: "/landing/admin/wehouseUsers" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "Sales Coordinator") {
                    console.log("inside-sc-landing---");
                    history.replace({ pathname: "/landing/salescoordinator/dashboard" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "Partner Manager") {
                    console.log("inside-pm-landing---");
                    history.replace({ pathname: "/landing/partnermanager/dashboard" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "PMO") {
                    console.log("inside-pmo-landing---");
                    history.replace({ pathname: "/landing/pmo/projects" })
                    break;
                }
             
                else if (menus[i].Menu_category_Label === "Customer") {
                    console.log("inside-Customer-landing---");
                    history.push({ pathname: "/landing/customer/dashboard" })
                    break;
                }
                

                else if (menus[i].Menu_category_Label === "Project Coordinator") {
                    console.log("inside--pco-landing---");
                    history.replace({ pathname: "/landing/pc/projects" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "Project Manager") {
                    console.log("inside--pco-landing---");
                    history.replace({ pathname: "/landing/pm/projects" })
                    break;
                }
                else if (menus[i].Menu_category_Label === "Sales Office") {
                    console.log("inside--Sales--Office--landing---");
                    history.replace({ pathname: "/landing/salesoffice/dashboard" })
                    break;
                }
            }
        }
    }

    //Regular Expressions
    const pswdValidate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/
    const emailValiadte = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    //Password Encryption:
    // console.log(md5("hello"))
    const encryptedPassword = md5(user.password);

    //Change Handler:
    const handleClick = () => {
        history.replace('/forgot')
    }
    //Email Button :
    const onEmailBtn = () => {
        setUser({ ...user, phone: '' })
        // setDisplayEmail(true)
        setCheck("email")
    }
    // Mobile Button :
    const onPhoneBtn = () => {
        setUser({ ...user, email: '' })
        // setDisplayEmail(false)
        setCheck("phone")
    }

    // Updating Handler: 
    const handlerChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    //
    const numberChange = (text) => {
        setUser({ ...user, phone: text })
    }

    //checkEmail:
    const checkEmail = async () => {
        if (emailValiadte.test(user.email)) {
            let data = {
                email: user.email
            }
            const response = await emailSignupService(data)
            // console.log('check-email---', response)
            if (response?.status === 'success') {
                if (response.data.emailExists) {

                }
                else {
                }
            }
        }
    }

    // checkMobile:
    const checkMobile = async () => {
        if (user.phone) {
            let data = {
                phone: user.phone
            }
            const response = await mobileSignupService(data)
            // console.log('check-mobile--', response)
            if (response.status === 'success') {
                if (response.data.mobileavail) {

                }
                else {
                }
            }
        }
    }
    const routeHandler = (UserId, userName, jwtToken) => {
        console.log("inside the route handler");
        // window.localStorage.clear();
        window.localStorage.setItem('user_Id', UserId);
        window.localStorage.setItem('userName', userName);
        window.localStorage.setItem('token', jwtToken);
        getMenuList(UserId)
        if(UserId){
            setUserId(UserId)
        }
        // history.replace({ pathname: "/landing" });
    }

    const getUserID = (userId) => {
        console.log("USERID inside: ", userId);
        const data=userId
        return (
            
                <userIdContext.Provider value={data}>
                {console.log("Provider value: ", data)}
                    <ProjectDetails />
                </userIdContext.Provider>
            
        )
    }


    //Submit Handler for landing page:
    const submitHandler = async (e) => {
        e.preventDefault();
        setCheckVar(true);
        if (user.email) {
            var userName = user.email
        }
        else if (user.phone) {
            var userName = user.phone
        }
        // else if(!user.email){
        //     setErrorMsg(()=>({emailError:"Field cannot be empty"}))
        // }
        // else if(!user.phone){

        //     setErrorMsg(()=>({phoneError:"Field cannot be empty"}))
        // }

        const data = {
            userName: userName,
            password: user.password,
        }

        // console.log('data :', data)
        //  if the input fieds are empty, dont go landing page :
        if (data?.userName && data.password) {
            // const response = await postSigninService(data);
            // console.log('response:', response)
            // console.log('userid:', response.data.UserId)
            // window.localStorage.setItem('isErrMsg', true);

            dispatch(loginLoader({ data: data, routehandler: routeHandler }))

            // if signin is true go to landing page:
            // if (response.status === "success" && response.data.issignin === true) {
            //     localStorage.setItem('user_Id', response.data.UserId)
            //     console.log('localstorage:::', localStorage.getItem('user_Id'));
            //     //   dispatch({type:'USER_ID',payload:response.data.UserId })
            //     window.localStorage.setItem('userName',data.userName);
            //     history.replace('/landing');
            // }
            // if(response.status ===  "Error"){
            //     alert(response.error)
            // }
            // else {
            //     setErrorMsg('Please Signin With Valid Credintials')
            // }
        }
        else {
            //    console.log("please fill all details");
        }
    }
    useEffect(() => {
        dispatch(clearLoginError());
    }, []);
    // console.log("isErrMsg--", isErrMsg, isErrMsg == 'true');
    return (
       <userIdContext.Provider value={userId}>
                {console.log("Provider value: ", userId)}
                    
       <div className="whSigninBg">
             
            <div className="whSigninBgOverlay">
                <div className="whSigninPanel">
                    <div className="whSiginContainer">
                        <div className="whIcon">
                            {/* <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/wh-logo.png" className="logo" alt="loading...." /> */}
                            <img src={imageAssets.WehouseLogo} className="hippi-signin-icon"  alt="0hippi" />
                           
                        </div>
                        <h1 className="titleLg">Sign in</h1>

                        <div className="whSigninForm mt-3">
                            <Form className="form" onSubmit={submitHandler}>
                                <div className="signinToggleBtns" role="group" aria-label="Basic example">
                                    <button type="button" className={`btn ${check === "email" ? "active" : ""}`} onClick={onEmailBtn}  >Email</button>
                                    <button type="button" className={`btn ${check === "phone" ? "active" : ""}`} onClick={onPhoneBtn}  >Mobile Number</button>
                                </div>
                                <div className="form-content">
                                <p className="response-error-msg">{message}</p>
                                    {check === "phone" &&
                                        <div className="inputField mobileField">
                                            <label>Mobile Number</label>
                                            <div className="mobileNumField">
                                                <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="IN"
                                                    value={value}
                                                    onChange={numberChange}
                                                />
                                                <input type="number" name="phone" className="number" value={user.phone}
                                                    onChange={handlerChange} onBlur={checkMobile} />
                                            </div>
                                            {checkVar && !user.phone.length && <small>Please enter a valid mobile number</small>}
                                        </div>

                                    }
                                    {check === "email" && <Form.Group controlId="formBasicEmail" className="inputField">
                                        <Form.Label className="email-label">Email </Form.Label>
                                        <Form.Control type="email" value={user.email} name="email" onChange={handlerChange} onBlur={checkEmail} className="email-field" />
                                    </Form.Group>}
                                    {check === "email" && checkVar && !user.email && <p><small>Please enter a valid email</small></p>}
                                    <Form.Group controlId="formBasicPassword" className="inputField">
                                        <div className="forgotPswd">
                                            <Form.Label className="password-label">Password</Form.Label>
                                        </div>
                                        <div className="pswdField">
                                            <Form.Control type={isRevealpswd ? "text" : "password"} value={user.password} name="password" onChange={handlerChange} className="password-field" />
                                            <img
                                                title={isRevealpswd ? "Hide password" : "Show password"}
                                                src={isRevealpswd ? showpswdimg : hidepswdimg}
                                                onClick={() => setisRevealpswd(prevState => !prevState)}
                                                className="pswd-icon" alt='passWord'
                                            />
                                        </div>
                                        {/* <p>{checkVar && !pswdValidate.test(user.password) && <small>Min 8 characters,<br />At least one uppercase [A-Z] and lowercase [a-z],<br />One number [0-9]<br />A special character[!,@,#,$]</small> }</p> */}
                                        <p>{checkVar && !pswdValidate.test(user.password) && <small>Enter a combination of at least 8 numbers,  <br /> one capital, letters and one special character
                                        </small>}</p>

                                    </Form.Group>
                                    <button type="submit" className="signInBtn" >Sign In</button>
                                    <Link className="forgot-btn" onClick={handleClick} to="../Forgot">Forgot Password?</Link>

                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </userIdContext.Provider>

    )
}


