import React, { useEffect, useState } from 'react';
import { useStyles } from './RoomInfoScreen.style';
import { RoomInfoScreensProps } from './RoomInfoScreen.types';
import { AddMember, CrossIcon, SearchIcon } from '../../../Icons/icons';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ROOM_DETAILS_BY_ID } from '../../queries/getRoomDetailsById';
// import { createSvgIcon } from '@mui/material/utils';
import GroupUsers from '../GroupUsers/GroupUsers';
import AddUserToRoom from '../AddUsersToRoom/AddUserToRoom';
import { GET_ACCOUNT_USERS } from '../../queries/getAccountUsers';
import { ADD_USERS_TO_GROUP } from '../../queries/addUsersToGroup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { toggleRoomDescriptionClicked,setchatRoomDetails } from '../../../../../Features/Slices/ChatServiceReduxSlice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { UPDATE_ROOM_ROLE_FOR_USER } from '../../queries/updateRoomRoleForUser';

// interface RoomDetails {
//   id: string;
//   name: string;
//   type: any;
//   associatedUsers: {
//     user_details: {
//       user_name: string;
//       user_id: string;
//     }[];
//     room_role: string;
//   }[];
// }

export const RoomInfoScreen: React.FC<RoomInfoScreensProps> = ({
  roomId,
  userDetails,
  isExpandChat
}: any) => {
  const { classes } = useStyles({ isExpandChat });
  console.log('roomId', roomId);
  const [roomDetailsById, setRoomDetailsById] = useState<any>();
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  //   const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [allAccountUsers, setAllAccountUsers] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const account_id = '30012025';
  console.log(selectedUser);
  //   const [groupMembers, setGroupMembers] = useState<number[]>([]);
  const { data: accountUserData } = useQuery(GET_ACCOUNT_USERS, {
    variables: { account_id },
    skip: !account_id
  });
  const { data: roomDetails, refetch } = useQuery(GET_ROOM_DETAILS_BY_ID, {
    variables: { roomId: roomId }
  });
  const [addUsersToGroup] = useMutation(ADD_USERS_TO_GROUP);
  const [updateRoomRoleForUser] = useMutation(UPDATE_ROOM_ROLE_FOR_USER);
  const dispatch = useDispatch();
  useEffect(() => {
    if (accountUserData && accountUserData.GetAccountUsers) {
      setAllAccountUsers(accountUserData.GetAccountUsers);
    }
  }, [accountUserData]);
  useEffect(() => {
    if (roomDetails && roomDetails.getRoomDetails) {
      setRoomDetailsById(roomDetails.getRoomDetails);
      setFilteredUsers(roomDetails.getRoomDetails?.associatedUsers);
    }
  }, [roomDetails]);

  const handleUserSearch = (e: any) => {
    const inputsearch = e.target.value;
    setSearchText(inputsearch);
    console.log('input changed', e.target.value);

    if (!inputsearch) {
      // If search is cleared, reset to original users
      setFilteredUsers(roomDetailsById?.associatedUsers);
    } else {
      const inputsearchQuery = inputsearch.toLowerCase();
      const filteredRooms = roomDetailsById?.associatedUsers.filter((user: any) => {
        const roomDetailName = user.user_details?.user_name?.toLowerCase() || '';
        return roomDetailName.includes(inputsearchQuery);
      });
      setFilteredUsers(filteredRooms);
    }
  };

  const handleSelectMembers = async (selectedIds: string[]) => {
    const addUser = {
      added_by_user_id: userDetails?.getUserById?.user_id,
      room_id: roomId,
      user_ids: selectedIds,
      chat_include_permission:false
    };
    console.log('Selected members:', selectedIds);
    try {
      const response = await addUsersToGroup({ variables: { input: addUser } });
      console.log('response from add users to group', response);
      toast.success('user added successfully');
      refetch();
    } catch (e) {
      console.log(e,"error")
    }
    setIsAddMemberOpen(false);
  };

  const handleToggle = () => {
    dispatch(toggleRoomDescriptionClicked());
  };

  const handleClose = () => {
    setIsAddMemberOpen(false);
  };

  const roomUserClicked = (user: any) => {
    console.log('selected User', user);
    setSelectedUser(user);
  };

  const logginedUserDetails = useSelector((state: any) => state.ChatServiceReduxSlice.userDetailsInRedux);
  console.log(logginedUserDetails, 'logginedUserDetails');

  const filterIsAdmin =
    roomDetailsById?.associatedUsers?.find(
      (u: any) => u?.user_details?.user_id === logginedUserDetails.user_id
    )?.room_role === 'Admin';
  console.log('filterIsAdmin', filterIsAdmin);

  const changeUserRole = async (popupState: any, user: any) => {
    console.log('admin is made successfully', user);
    popupState.close();
    const updateUserRole = {
      room_id: roomId,
      updated_by: logginedUserDetails.user_id,
      updated_for: user.user_details.user_id,
      updated_room_role: ''
    };
    if (user.room_role === 'Admin') {
      updateUserRole.updated_room_role = 'Member';
    } else {
      updateUserRole.updated_room_role = 'Admin';
    }
    try {
      const response = await updateRoomRoleForUser({ variables: { input: updateUserRole } });
      refetch();
      console.log('successfully changes the role', response);
    } catch (e) {
      console.log('error in changing Role', e);
    }
  };

  const isAdminForGroup = (user: any) => {
    // console.log("user",user);
    if (
      user.room_role === 'Admin' &&
      roomDetailsById.created_by_user_id !== user.user_details.user_id &&
      user.user_details.user_id !== logginedUserDetails.user_id
    ) {
      return (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <div {...bindTrigger(popupState)}>
                <GroupUsers
                  userDetails={user}
                  key={user.user_details?.user_name}
                  roomUserClicked={roomUserClicked}
                />
              </div>

              <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right' // Align the popup to the right
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right' // Ensure the popup expands towards the right
                }}>
                {/* <MenuItem onClick={popupState.close}>Make Admin</MenuItem> */}
                <MenuItem onClick={() => changeUserRole(popupState, user)}>Remove Admin</MenuItem>
                <MenuItem onClick={popupState.close} disabled>
                  Remove from Group
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      );
    } else if (
      roomDetailsById.created_by_user_id === user?.user_details?.user_id ||
      user?.user_details?.user_id === logginedUserDetails?.user_id
    ) {
      return (
        <GroupUsers
          userDetails={user}
          key={user.user_details?.user_name}
          roomUserClicked={roomUserClicked}
        />
      );
    }
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <div {...bindTrigger(popupState)}>
              <GroupUsers
                userDetails={user}
                key={user.user_details?.user_name}
                roomUserClicked={roomUserClicked}
              />
            </div>

            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right' // Align the popup to the right
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right' // Ensure the popup expands towards the right
              }}>
              <MenuItem onClick={() => changeUserRole(popupState, user)}>Make Admin</MenuItem>
              {/* <MenuItem onClick={popupState.close}>Remove Admin</MenuItem> */}
              <MenuItem onClick={popupState.close} disabled>
                Remove from Group
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  const usersCount = roomDetailsById?.associatedUsers?.filter(
    (groupUser: any) => groupUser?.user_details !== null && groupUser?.user_details !== undefined
  ).length;

  return (
    <div className={classes.groupInfoContainer}>
      <div className={classes.groupHeaderContainer}>
        <div onClick={handleToggle} className={classes.backButton}>
          <CrossIcon />
        </div>
        {roomDetailsById?.type !== 'user' ? (
          <h1 className={classes.header}>Room Info</h1>
        ) : (
          <h1 className={classes.header}>Contact Info</h1>
        )}
      </div>
      <h1 className={classes.groupName}>{roomDetailsById?.name}</h1>
      {roomDetailsById?.type !== 'user' ? (
        <div className={classes.searchUsersListContainer}>
          <p className={classes.memberCount}>{usersCount ?? 0} members</p>
          <div className={classes.searchContainer}
             style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgb(244, 245, 249)',
              padding: '5px 10px',
              borderRadius: '20px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              margin:"10px",
              height:"30px"
              
              
            }}>
            <SearchIcon />
            <input
              type="text"
              placeholder="Search..."
              className={classes.searchBar}
              value={searchText}
              onChange={handleUserSearch}
              style={{
                border: '0px',
                outline: 'none',
                fontSize: '12px',
                width: '100%',
                              height:"20px",
                backgroundColor: 'rgb(244, 245, 249)',
              }}
            />
          </div>
        </div>
      ) : null}
      {roomDetailsById?.type !== 'user' ? (
        <div className={classes.addMemberContainer} onClick={() => setIsAddMemberOpen(true)}>
          <div className={classes.plusIconContainer}>
            <AddMember color="#ffffff" />
          </div>
          <AddUserToRoom
            isOpen={isAddMemberOpen}
            onClose={handleClose}
            contacts={allAccountUsers}
            onSelectMembers={handleSelectMembers}
            roomDetails={roomDetails}
            
          />
          <p className={classes.addMemberHeader}> Add member</p>
        </div>
      ) : null}
      {filterIsAdmin ? (
        <ul className={classes.groupUsersList}>
          {roomDetailsById?.type !== 'user' ? (
            filteredUsers.length === 0 ? (
              <p className={classes.noUsersFound} style={{color:"red"}}>No user found</p>
            ) : (
              filteredUsers.map((user: any) => isAdminForGroup(user))
            )
          ) : null}
        </ul>
      ) : (
        <ul className={classes.groupUsersList}>
          {roomDetailsById?.type !== 'user'
            ? (filteredUsers.length===0 ? (<p className={classes.noUsersFound} style={{color:"red"}}>No user found</p>):filteredUsers?.map((user: any) => (
              <GroupUsers
                userDetails={user}
                key={user.user_details?.user_name}
                roomUserClicked={roomUserClicked}
              />
            )) )   
            : null}
        </ul>
      )}
      {/* <ul className={classes.groupUsersList}>
        {roomDetailsById?.type !== 'user'
          ? roomDetailsById?.associatedUsers?.map((user: any) => (
              <GroupUsers
                userDetails={user}
                key={user.user_details?.user_name}
                roomUserClicked={roomUserClicked}
              />
            ))
          : null}
      </ul> */}
    </div>
  );
};

export default RoomInfoScreen;
