const handleMultipleSocketData = (socketDataArray, setNotifications) => {
    console.log(socketDataArray, "socketDataArray");
  
    const userId = Number(window.localStorage.getItem("user_Id"));
    console.log(userId, "userId");
  
    if (!Array.isArray(socketDataArray)) {
      console.warn("Expected an array but received:", socketDataArray);
      return;
    }
  
    let newNotifications = [];
  
    socketDataArray.forEach((socketData) => {
      if (socketData?.recipient_ids?.includes(userId)) {
        console.log("New notification received via socket", socketData);
        let newNotification = {};
            
        switch (socketData.entity_type) {
            case 12: // Lead Allocation
                newNotification = {
                    title: "Lead Allocation",
                    description: `Hi ${socketData._receiver_name}, ${socketData.lead_count} new leads have been allocated to you by ${socketData._actor_name}.`,
                };
                break;

            case 13: // Lead Reallocation
                if (socketData.notification_content.includes("reallocated to")) {
                    newNotification = {
                        title: "Lead Reallocation",
                        description: `Hi ${socketData.previous_user_name}, Your lead ${socketData.lead_name} has been reallocated to ${socketData.present_user_name}.`,
                    };
                } else {
                    newNotification = {
                        title: "Lead Reallocation",
                        description: `Hi ${socketData.present_user_name}, Lead ${socketData.lead_name} has been reallocated from ${socketData.previous_user_name} by ${socketData._actor_name}.`,
                    };
                }
                break;

            default:
                console.warn("Unhandled entity_type:", socketData.entity_type);
                return; // Skip unhandled entity types
        }

        newNotifications.push({
            ...newNotification,
            entityTypeId: socketData.entity_type,
            notificationId: socketData.notification_id,
            read: socketData.isRead,
        });
      }
    });
  
    if (newNotifications.length > 0) {
      setNotifications((prev) => {
        console.log(prev, "Previous Notifications List");
  console.log(newNotifications,"newNotifications")
  const uniqueNotifications = [
    ...newNotifications,
    ...prev.filter(
      (notif) =>
        !newNotifications.some(
          (newNotif) => newNotif.notificationId === notif.notificationId
        )
    ),
  ];

  return uniqueNotifications;
      });
    }
  };
  
  export default handleMultipleSocketData;
  