import React from "react"
import { toast } from "react-toastify"
import { dateFormatter } from "../../../../../Utilities/helpers"
import AddTask from "../AddTask/AddTask"
import LeadDetails from "../LeadDetails/LeadDetails"
import SaveAndCancel from "./SaveAndCancel/SaveAndCancel"
import ScheduleCall from "./ScheduleCall/ScheduleCall"
import ScheduleMeeting from "./ScheduleMeeting/ScheduleMeeting"
import TaskAndDescription from "./TaskAndDescription/TaskAndDescription"
import moment from 'moment'
const scheduleButtonList = ["Add Task", "Schedule Call", "Schedule Meeting"]
const GenericTask = ({
    taskAdding,
    setTaskAdding,
    selectedUser,
    // taskDetails,
    // setTaskDetails,
    handleSave,
    handleCancel,
    outcome,
    setOutcome,
    leadStatus,
    setLeadStatus,
    taskList,
    setActivityTypeName=()=>{}
}) => {
    console.log("selecteduser", selectedUser)
    /* handle the outcomes */
    const handleOutComes = (value) => {
    
        console.log("inside the handle outcomes", value)
        setOutcome(value)
        setActivityTypeName(value)
    }
  
    /* check the all fields validation based on outcome poistion */
    const fieldsValidation = () => {
        console.log(outcome)
        if (
            outcome === "Schedule Call" &&
            leadStatus?.taskName &&
            leadStatus?.taskDescription &&
            leadStatus?.callScheduleDate &&
            leadStatus?.callScheduleTime
        ) {
            // handleSave(type)
            let temp = leadStatus
            if (!temp.leadActivities?.find((value) => value === outcome)) {
                temp.leadActivities = [outcome]
            }

            console.log("temp", temp)
            setLeadStatus(temp)
            return true
        } else if (
            outcome === "Schedule Meeting" &&
            leadStatus?.taskName &&
            leadStatus?.taskDescription &&
            leadStatus?.meetingAttendees.length &&
            leadStatus?.meetingScheduleDate &&
            leadStatus?.meetingScheduleTime
        ) {
            let temp = leadStatus
            if (!temp.leadActivities.find((value) => value === outcome)) {
                temp.leadActivities = [outcome]
            }

            console.log("temp", temp)
            setLeadStatus(temp)
            return true
        } else if (
            outcome === "Add Task" &&
            leadStatus?.taskName &&
            leadStatus?.taskDescription &&
            leadStatus?.taskStartDate &&
            leadStatus?.taskEndDate
        ) {
            let temp = leadStatus
            if (!temp.leadActivities?.find((value) => value === outcome)) {
                temp.leadActivities = [outcome]
            }
            console.log("temp", temp)
            setLeadStatus(temp)

            return true
        } else {
            
            toast.error("Please fill all details")
        }
    }

    let strippedHtml = leadStatus && leadStatus.taskDescription.replace(/<div>|<\/div>/g, "\n")
    console.log("before-brr--", strippedHtml)
    strippedHtml = strippedHtml.replace(/<br>|&nbsp;/g, "")
    console.log("strippedHtml--", strippedHtml)

    const handleValidations = (type) => {
        console.log("type", type, outcome)
        if (outcome === "Schedule Call" && fieldsValidation()) {
            console.log("taskDescription-0000--", strippedHtml)
            setLeadStatus((values) => ({ ...values, taskDescription: strippedHtml }))
            handleSave(type)
        } else if (outcome === "Schedule Meeting" && fieldsValidation()) {
            setLeadStatus((values) => ({ ...values, taskDescription: strippedHtml }))
            handleSave(type)
        } else if (outcome === "Add Task" && fieldsValidation()) {
            setLeadStatus((values) => ({ ...values, taskDescription: strippedHtml }))
            handleSave(type)
        } else if (outcome === "") {
            toast.warning("Please Select atleast one activity")
        }
    }

    /* storing the all field values using this method*/
    const handleLeadActivities = (type) => (e) => {
        console.log("inside the handle lead activity", type, e)
        if (type === "schedulecalldate") {
            setLeadStatus((values) => ({ ...values, callScheduleDate: e !== null ? e.format("YYYY-MM-DD") : null }))
        } else if (type === "schedulecalltime") {
            setLeadStatus((values) => ({ ...values, callScheduleTime: e !== null ? e.format("h:mm A") : null }))
        } else if (type === "schedulemeetingdate") {
            setLeadStatus((values) => ({ ...values, meetingScheduleDate: e !== null ? e.format("YYYY-MM-DD") : null }))
        } else if (type === "schedulemeetingtime") {
            setLeadStatus((values) => ({ ...values, meetingScheduleTime: e !== null ? e.format("h:mm A") : null }))
        } else if (type === "taskTitle") {
            setLeadStatus((values) => ({ ...values, taskName: e.target.value }))
        } else if (type === "taskDescription") {
            setLeadStatus((values) => ({ ...values, taskDescription: e.target.value }))
        } else if (type === "taskStartDate") {
            console.log(e,"here")
            setLeadStatus((values) => ({
                ...values,
                taskEndDate: null,
                taskStartDate: e !== null ? moment(e).format("YYYY-MM-DD")  : null
            }))
        } else if (type === "taskEndDate") {
            setLeadStatus((values) => ({ ...values, taskEndDate: e !== null ?moment(e).format("YYYY-MM-DD"): null }))
        } else if (type === "meetingAttendees") {
            setLeadStatus((values) => ({ ...values, meetingAttendees: e }))
        }
    }

    console.log("outcome", outcome, leadStatus)

    return (
        <div>
            <LeadDetails selectedUser={selectedUser} />
            <hr />
            <div>
                <label>OutComes</label>
                <div className="leadbtns mb-3">
                    {scheduleButtonList?.map((name, index) => {
                        return (
                            <button
                                onClick={() => handleOutComes(name)}
                                className={`btn btn-sm ${name} ${outcome === name ? `${name}-active` : ""}`}
                                key={index}
                            >
                                {name}
                            </button>
                        )
                    })}
                </div>
            </div>
            <TaskAndDescription
                leadStatus={leadStatus}
                setLeadStatus={setLeadStatus}
                handleLeadActivities={handleLeadActivities}
                taskList={taskList}
            />
            <div>
                <div class="dtPicker">
                    {
                        outcome === "Schedule Call" ? (
                            <ScheduleCall
                                handleLeadActivities={handleLeadActivities}
                                leadStatus={leadStatus}
                                setLeadStatus={setLeadStatus}
                            /> //yes
                        ) : outcome === "Schedule Meeting" ? ( //No
                            <ScheduleMeeting
                                handleLeadActivities={handleLeadActivities}
                                leadStatus={leadStatus}
                                setLeadStatus={setLeadStatus}
                            /> //yes
                        ) : outcome === "Add Task" ? (
                            <AddTask
                                handleLeadActivities={handleLeadActivities}
                                leadStatus={leadStatus}
                                setLeadStatus={setLeadStatus}
                            />
                        ) : null //No
                    }
                </div>
            </div>
            <div className="modalFooter">
                <SaveAndCancel handleSave={handleValidations} handleCancel={handleCancel} isTaskAdding={taskAdding} />
            </div>
        </div>
    )
}

export default GenericTask
