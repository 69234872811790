import React, { useState, useEffect } from "react"
import "./CustomerDetails.css"
import "./CreateCustomers.scss"
import { AddCustomerService, UpdtaeCustomersDataService } from "../../../../../Service/SeCustomersServices"
import { toast } from "react-toastify"
import { blockInvalidChar, checkEmail, EmailErrorClear } from "../../../../../Utilities/helpers"
import { useDispatch, useSelector } from "react-redux"
import { customerLoader } from "../../../../../Features/Slices/CustomersSlice"
import { getStatusListType } from "../../../../../Features/Saga/getStatusList"
import { statusListApi } from "../../../../../Service/CommonDropdownApiCalls/commonDropdownApiCalls"
import Checkbox from "antd/lib/checkbox/Checkbox"
import axios from "axios";
import appSyncConfig from "../../../../../Service/RightChatEnginePanel/aws-exports";
import { event } from "jquery"

const emailValidate =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function CustomerDetails({
    type,
    userId,
    toggleHandler,
    newLead,
    handleTabs,
    setCustomerIdFromApi,
    customerIdFromApi
}) {
    console.log("cusomerid from api", customerIdFromApi, newLead)
    const [customerDetails, setCustomerDetails] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        Aadhar: "",
        panCard: "",
        residentialAddress: "",
        maillingAddress: "",
        status: "Active",
        sameAddress:false,
        checkedBox:false
    })
    const [emailError, setEmailError] = useState(false)
    const [checkVar, setCheckVar] = useState(false)
    const [statusList, setStatusList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const { customerDataById, loading } = useSelector((state) => state.Customers)
    const [errorMsg, setErrorMsg] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        Aadhar: "",
        panCard: "",
        residentialAddress: "",
        maillingAddress: ""
    })
    const [checkedBox,setCheckedBox]=useState(false)
    // console.log("customer by id",customerDataById,loading);
    // const statusList = useSelector((state) => state.getStatusListSlice.status);
    // console.log("statusList:", statusList, newLead);

    // console.log('type:', type, "userId :", userId);

    /* save the field values in local state*/
    const handlerChange = (e) => {
        const { name, value } = e.target
        setErrorMsg((prev) => ({ ...prev, [name]: "" }))
        if (name === "Aadhar") {
            setCustomerDetails((prevState) => ({ ...prevState, [name]: value.slice(0, 12) }))
        } else if (name === "phone" || name === "panCard") {
            setCustomerDetails((prevState) => ({ ...prevState, [name]: value.slice(0, 10) }))
        } else {
            setCustomerDetails((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }
        console.log("event",e)
        if (name=="sameAddress"){
            setCustomerDetails((prev) => ({ ...prev, sameAddress: e?.target?.checked }))
            if (e?.target?.checked) {
                setCustomerDetails((prev) => ({ ...prev, maillingAddress: prev.residentialAddress }))
              
                
            } else {
                setCustomerDetails((prev) => ({ ...prev, maillingAddress: "" }))
                
            }
        }
    }

    const updateOrAddApi = async (typeofApi, buttonType, customerDetails) => {
        console.log("values in update or add api", typeofApi, buttonType, customerDetails);
        try {
            if (typeofApi === "add") {

                
                const response = await AddCustomerService(customerDetails);
    
                if (response.status === "success" && response.data.add_customers === true) {
                    const customerName = customerDetails?.FirstName + ' ' + customerDetails?.LastName;
                    const addedUserId = response?.data?.userId;
                    const customerEmail = customerDetails?.Email;
    
                    async function addUserToChatAccount() {
                        const appsyncUrl =appSyncConfig.aws_appsync_graphqlEndpoint;
                        const params = {
                            accountId: '30012025',
                            userId: addedUserId,
                            email: customerEmail,
                        };
    
                        const apiKey = appSyncConfig.aws_appsync_apiKey;
    
                        try {
                            const checkAccountQuery = `
                                query accountIDExists($account_id: ID!) {
                                    accountIDExists(account_id: $account_id)
                                }
                            `;
    
                            const checkAccountResponse = await axios.post(
                                appsyncUrl,
                                {
                                    query: checkAccountQuery,
                                    variables: { account_id: params.accountId },
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "x-api-key": apiKey,
                                    },
                                }
                            );
    
                            const accountExists = checkAccountResponse.data.data.accountIDExists;
                            let chatResponse = { ...response, isAccountExists: accountExists };
                            console.log(`Chat: Account Exists Query Response ===> ${accountExists}`);
    
                            if (!accountExists) {
                                console.log(`Chat: Account does not exist for ID ${params.accountId}`);
                                return { ...chatResponse, Error: "Account does not exist" };
                            }
    
                            const checkUserQuery = `
                                query userIDExists($user_id: ID!) {
                                    userIDExists(user_id: $user_id)
                                }
                            `;
    
                            const checkUserResponse = await axios.post(
                                appsyncUrl,
                                {
                                    query: checkUserQuery,
                                    variables: { user_id: addedUserId },
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "x-api-key": apiKey,
                                    },
                                }
                            );
    
                            const userExists = checkUserResponse.data.data.userIDExists;
                            chatResponse = { ...chatResponse, isUserExists: userExists };
                            console.log(`Chat: User Exists Query Response ===> ${userExists}`);
    
                            if (!userExists) {
                                const chatParams = {
                                    userId: addedUserId,
                                    email: customerEmail,
                                    chatUserName: customerName,
                                    accountId: params.accountId,
                                };
    
                                const userCreation = await createChatUser(chatParams);
                                chatResponse = { ...chatResponse, userCreationResponse: userCreation };
                                console.log(`Chat Response ===>`, chatResponse);
                            }
    
                            const checkUserAccountConnectionQuery = `
                                query userAccountConnectionExists($account_id: ID!, $user_id: ID!) {
                                    userAccountConnectionExists(account_id: $account_id, user_id: $user_id)
                                }
                            `;
    
                            const checkUserAccountConnectionResponse = await axios.post(
                                appsyncUrl,
                                {
                                    query: checkUserAccountConnectionQuery,
                                    variables: {
                                        account_id: params.accountId,
                                        user_id: addedUserId,
                                    },
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "x-api-key": apiKey,
                                    },
                                }
                            );
    
                            const userAccountConnectionExists =
                                checkUserAccountConnectionResponse.data.data.userAccountConnectionExists;
                            chatResponse = { ...chatResponse, userAccountConnectionExists };
    
                            console.log(`Chat: User-Account Connection Exists Query Response ===> ${userAccountConnectionExists}`);
    
                            if (!userAccountConnectionExists) {
                                const addUserToAccountMutation = `
                                    mutation addUserToAccount($account_id: ID!, $user_id: ID!) {
                                        addUserToAccount(account_id: $account_id, user_id: $user_id) {
                                            account_id
                                            created_date
                                            user_id
                                        }
                                    }
                                `;
    
                                const addUserToAccountResponse = await axios.post(
                                    appsyncUrl,
                                    {
                                        query: addUserToAccountMutation,
                                        variables: { account_id: params.accountId, user_id: addedUserId },
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                            "x-api-key": apiKey,
                                        },
                                    }
                                );
    
                                chatResponse = { ...chatResponse, addUserToAccountResponse: addUserToAccountResponse.data.data.addUserToAccount };
                                console.log(`Chat: addUserToAccount Mutation Response ===>`, addUserToAccountResponse.data);
                            }
    
                            console.log("Final Chat Response ===>", chatResponse);
                            return chatResponse;
                        } catch (error) {
                            console.error("Error adding user to chat account", error);
                            return { ...response, Error: error.message };
                        }
                    }
                    async function createChatUser(chatParams) {
                              let response;
                           
                        const appsyncUrl = appSyncConfig.aws_appsync_graphqlEndpoint;
             
                        const apiKey=appSyncConfig.aws_appsync_apiKey;  
                    
                        try {
                    
                            const checkAccountQuery = `
                                        query accountIDExists($account_id: ID!) {
                                            accountIDExists(account_id: $account_id)
                                        }
                                             `;
                    
                            const checkAccountResponse = await axios.post(appsyncUrl,
                                {
                                    query: checkAccountQuery,
                                    variables: { account_id: chatParams.accountId },
                                },
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'x-api-key': apiKey,
                                    },
                                }
                            );
                    
                            const accountExists = checkAccountResponse.data.data.accountIDExists;
                            response = { ...response, accountExists };
                            console.log(`Chat:Account Exists Query Response ===> ${accountExists}`);
                    
                            if (accountExists) {
                                const checkUserQuery = `
                                                        query userIDExists($user_id: ID!) {
                                                            userIDExists(user_id: $user_id)
                                                        }
                                                        `;
                                const checkUserResponse = await axios.post(appsyncUrl,
                                    {
                                        query: checkUserQuery,
                                        variables: { user_id: chatParams.userId },
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'x-api-key': apiKey,
                                        },
                                    }
                                );
                    
                                const userExists = checkUserResponse.data.data.userIDExists;
                                response = { ...response, userExists }
                                console.log(`Chat:User Exists  Query  Response ===> ${userExists}`);
                    
                                if (!userExists) {
                                    const createUserMutation = `
                                                                mutation addUser($input: addUserInput!) {
                                                                    addUser(input: $input) {
                                                                        email_id
                                                                        user_id
                                                                        user_name
                                                                    }
                                                                    }
                                                                `;
                    
                                    const createUserResponse = await axios.post(
                                        appsyncUrl,
                                        {
                                            query: createUserMutation,
                                            variables: {
                                                input: {
                                                    email_id: chatParams.email,
                                                    user_name: chatParams.chatUserName,
                                                    user_id: chatParams.userId,
                                                },
                                            },
                                        },
                                        {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'x-api-key': apiKey,
                                            },
                                        }
                                    );
                                    response = { ...response, createUserResponse: createUserResponse.data.data.addUser }
                                    console.log('Chat:User Created Mutation Response=====>:', createUserResponse.data);
                                }
                    
                                const checkUserAccountConnectionQuery = `
                                                    query userAccountConnectionExists($account_id: ID!, $user_id: ID!) {
                                                        userAccountConnectionExists(account_id: $account_id, user_id: $user_id)
                                                    }
                                                    `;
                    
                                const checkUserAccountConnectionResponse = await axios.post(appsyncUrl,
                                    {
                                        query: checkUserAccountConnectionQuery,
                                        variables: {
                                            account_id: chatParams?.accountId,
                                            user_id: chatParams?.userId,
                                        },
                                    },
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'x-api-key': apiKey,
                                        },
                                    }
                                );
                    
                                const userAccountConnectionExists =
                                    checkUserAccountConnectionResponse.data.data.userAccountConnectionExists;
                                response = { ...response, userAccountConnectionExists }
                    
                                console.log(`Chat: User-Account Connection Exists query response====> ${userAccountConnectionExists}`);
                    
                                if (!userAccountConnectionExists) {
                                    const addUserToAccountMutation = `
                                                                    mutation addUserToAccount($account_id: ID!, $user_id: ID!) {
                                                                        addUserToAccount(account_id: $account_id, user_id: $user_id) {
                                                                        account_id
                                                                        created_date
                                                                        user_id
                                                                        }
                                                                    }
                                                                    `;
                    
                                    const addUserToAccountResponse = await axios.post(appsyncUrl,
                                        {
                                            query: addUserToAccountMutation,
                                            variables: { account_id: chatParams.accountId, user_id: chatParams.userId },
                                        },
                                        {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'x-api-key': apiKey,
                                            },
                                        }
                                    );
                                    response = { ...response, addUserToAccountResponse: addUserToAccountResponse.data.data.addUserToAccount }
                    
                                    console.log('Chat:addUserToAccount Mutation Response =========>', addUserToAccountResponse.data);
                    
                    
                    
                                }
                            }
                            else {
                                response = { ...response, error: "Account Not Exists in Chat DB" }
                            }
                            console.log("CHAT RESPONSE=========>", response)
                            return response
                        } catch (error) {
                            console.error('Chat:Error while adding  user to chat===>', error);
                            response = { ...response, error }
                            console.log("chatResponse in catch", response)
                            return response;
                        }
                    
                    
                    
                    }
    
                    await addUserToChatAccount();
    
                    toast.success("Customer data is Added successfully");
                    setCustomerIdFromApi(response.data.userId);
    
                    buttonType === "save&next" ? handleTabs("Project") : toggleHandler("");
                } else {
                    toast.error("Failed to Add customer");
                }
            } else if (typeofApi === "update") {
                const response = await UpdtaeCustomersDataService(customerDetails);
                console.log("CustomerDataById::", response);
                if (response?.status === "success") {
                    toast.success(response.data.message);
                    setCustomerIdFromApi(response.customerId);
                    buttonType === "save&next" ? handleTabs("Project") : toggleHandler("");
                } else {
                    toast.error("Failed to Update Customer");
                }
            }
        } catch (error) {
            console.log("error", error);
            toast.error("Something went wrong");
        }
    };
    

    /*submit handler for api hitting*/
    const submitHandler = async (check) => {
        if (isLoading) return; 
        setCheckVar(true)
        if (validateForm()) {
            setIsLoading(true);
            try{
            if (type === "edit" || customerIdFromApi || type === "updateproject") {
                const CustomerDataById = {
                    aadhar: customerDetails.Aadhar,
                    email: customerDetails.email,
                    firstName: customerDetails.firstName,
                    lastName: customerDetails.lastName,
                    mobile: customerDetails.phone,
                    pan: customerDetails.panCard,
                    residentialAddress: customerDetails.residentialAddress,
                    userId: customerDataById?.userid,
                    mailingAddress: customerDetails.maillingAddress,
                    status: customerDetails.status,
                    customerId: customerDataById?.userid || customerIdFromApi,
                    userId: Number(window.localStorage.getItem("user_Id")),
                    setCheckedBox:customerDetails.maillingAddress===customerDetails.residentialAddress
                }

             await updateOrAddApi("update", check, CustomerDataById)
            } else if (type === "newlead") {
                const customerData = {
                    FirstName: customerDetails.firstName,
                    LastName: customerDetails.lastName,
                    PhoneNumber: customerDetails.phone,
                    Email: customerDetails.email,
                    Aadhar: customerDetails.Aadhar,
                    PAN: customerDetails.panCard,
                    ResidentialAddress: customerDetails.residentialAddress,
                    MaillingAddress: customerDetails.maillingAddress,
                    Status: customerDetails.status,
                    leadId: newLead?.otpId || newLead?.leadId,
                    userId: Number(window.localStorage.getItem("user_Id")),
                    
                }
               await updateOrAddApi("add", check, customerData)
            } else {
                const customerData = {
                    FirstName: customerDetails.firstName,
                    LastName: customerDetails.lastName,
                    PhoneNumber: customerDetails.phone,
                    Email: customerDetails.email,
                    Aadhar: customerDetails.Aadhar,
                    PAN: customerDetails.panCard,
                    ResidentialAddress: customerDetails.residentialAddress,
                    MaillingAddress: customerDetails.maillingAddress,
                    Status: customerDetails.status,
                    leadId: null,
                    userId: Number(window.localStorage.getItem("user_Id"))
                }
                await updateOrAddApi("add", check, customerData)
                
            }
        }catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred. Please try again."); 
          } finally {
            setIsLoading(false);
          }
         } else {
            toast.error("Please fill the required fields")
        }
    }

    /* Function for GetCustomersDataById and add new lead from my leads screen*/
    const GetCustomerDataById = () => {
        if (type === "edit" || type === "updateproject") {
            const {
                aadhar,
                firstname,
                lastname,
                mailing_address,
                mobile,
                pan_card,
                status,
                submitted_time,
                userid: customerId,
                email,
                temp_address
            } = customerDataById

            setCustomerDetails({
                firstName: firstname,
                lastName: lastname,
                phone: mobile,
                email: email,
                Aadhar: aadhar,
                panCard: pan_card,
                residentialAddress: temp_address,
                maillingAddress: mailing_address,
                status: status,
                setCheckedBox:temp_address===mailing_address
            })
        }
        if (type === "newlead") {
            const { email, mobile, name,lastName } = newLead
            const { leadMobile, leadName, leadEmail,leadLastName } = newLead
            console.log(newLead,"newLead")
            const [firstName, last] = name ? name.split(" ") : [null, lastName];

            setCustomerDetails({
                firstName: firstName || leadName,
                lastName: lastName|| leadLastName,
                phone: mobile || leadMobile,
                email: email || leadEmail,
                Aadhar: "",
                panCard: "",
                residentialAddress: "",
                maillingAddress: "",
                status: "Active",
                setCheckedBox:""
            })
        }
    }

    useEffect(async () => {
        // dispatch(getStatusListType("Customer"))

        //  get staus list api :
        const statusListResponse = await statusListApi()
        console.log("statusListResponse:", statusListResponse)
        setStatusList(statusListResponse.data)
    }, [])
    console.log("email error:", emailError)


    useEffect(() => {
        if (Object.keys(customerDataById).length > 0 || newLead ) {
            GetCustomerDataById();  
            console.log("check it v");
        }
    }, [customerDataById,newLead]);

   

    // Form Validation:
    const validateForm = () => {
        let IsValid = true
        if (!customerDetails.firstName) {
            setErrorMsg((prev) => ({ ...prev, ...prev, firstName: "First Name is required" }))
            IsValid = false
        }
        if (!customerDetails.lastName) {
            setErrorMsg((prev) => ({ ...prev, ...prev, lastName: "Last Name is required" }))
            IsValid = false
        }
        if (customerDetails.phone.length !== 10) {
            setErrorMsg((prev) => ({ ...prev, ...prev, phone: "Enter 10 digits Mobile number" }))
            IsValid = false
        }
        if (!emailValidate.test(customerDetails?.email)) {
            setErrorMsg((prev) => ({ ...prev, ...prev, email: "Enter Valid Email" }))
            IsValid = false
        }
        if (customerDetails.Aadhar.length !== 12) {
            setErrorMsg((prev) => ({ ...prev, ...prev, Aadhar: "Enter 12 digits Aadhaar number" }))
            IsValid = false
        }
        if (!customerDetails.panCard || customerDetails.panCard.length < 10) {
            setErrorMsg((prev) => ({ ...prev, ...prev, panCard: "Enter 10 digits PAN number" }))
            IsValid = false
        }
        if (!customerDetails.residentialAddress) {
            setErrorMsg((prev) => ({ ...prev, residentialAddress: "Address is required" }))
            IsValid = false
        }
        // if (!customerDetails.maillingAddress) {
        //     IsValid = false
        // }

        return IsValid
    }
    console.log("customerDetails", customerDetails)

const isValid = 
  (customerDetails.firstName?.trim() || "") !== "" &&
  (customerDetails.lastName?.trim() || "") !== "" &&
  (customerDetails.phone?.trim() || "") !== "" &&
  (customerDetails.email?.trim() || "") !== "" &&
  (customerDetails.Aadhar?.trim() || "") !== "" &&
  (customerDetails.panCard?.trim() || "") !== "" &&
  (customerDetails.residentialAddress?.trim() || "") !== "" &&
  (customerDetails.sameAddress || (customerDetails.maillingAddress?.trim() || "") !== "");

    return (
        <div className="contentBody detail">
            {/* {emailError && toast.error( "Email already Exist")} */}
            <div>
                <div className="row">
                    <div className="col-md-8" style={{ margin: "auto" }}>
                        <div className="whCard">
                            <div className="cardHeader">
                                <span>Customers Detassils</span>
                                <div className="d-flex customer-details-btn">
                                    <select
                                        value={customerDetails.status}
                                        name="status"
                                        className={`selectStatus ${
                                            (customerDetails.status === "Active" && "Active") ||
                                            (customerDetails.status === "Inactive" && "Inactive") ||
                                            (customerDetails.status === "Deleted" && "Deleted")
                                        }`}
                                        onChange={handlerChange}
                                    >
                                        {statusList &&
                                            statusList.map((item, index) => (
                                                <option key={index} value={item.status}>
                                                    {item.status}
                                                </option>
                                            ))}
                                    </select>
                                    {/* <button onClick={() => submitHandler("save&close")} className="btn saveBtnclose">Save & Close</button> */}
                                    <button onClick={() => submitHandler("save&next")} className="btn saveBtn" disabled={!isValid || isLoading}>
                                    {isLoading ? (
        <>
          <div className="spinner" style={{ display: 'inline-block', marginRight: '8px' }} />
          <span>Saving...</span>
        </>
      ) : (
        'Save & Next'
      )}
                                    </button>
                                </div>
                            </div>
                            <div className="cardBody">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>First Name *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={customerDetails.firstName}
                                                onChange={handlerChange}
                                                placeholder="Enter First Name"
                                                className={`form-control ${errorMsg?.firstName && "errorInput"}`}
                                            />
                                        </div>
                                        {errorMsg?.firstName ? (
                                            <small className="emailExist">{errorMsg?.firstName}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Last Name *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={customerDetails.lastName}
                                                onChange={handlerChange}
                                                placeholder="Enter Last Name"
                                                className={`form-control ${errorMsg?.lastName && "errorInput"}`}
                                            />
                                        </div>
                                        {errorMsg?.lastName ? (
                                            <small className="emailExist">{errorMsg?.lastName}</small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>Mobile Number *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                name="phone"
                                                value={customerDetails.phone}
                                                onChange={handlerChange}
                                                className={`form-control ${errorMsg?.phone && "errorInput"}`}
                                                onKeyDown={blockInvalidChar}
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                        {errorMsg.phone ? (
                                            <small className="emailExist">{errorMsg?.phone}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Email Id *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="email"
                                                name="email"
                                                value={customerDetails.email}
                                                onChange={handlerChange}
                                                className={`form-control ${errorMsg?.email && "errorInput"}`}
                                                placeholder="Enter Email Id"
                                                onBlur={() => {
                                                    checkEmail(customerDetails.email, setEmailError)
                                                }}
                                            />
                                        </div>
                                        {errorMsg?.email ? (
                                            <small className="emailExist">{errorMsg?.email}</small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>Aadhaar *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                name="Aadhar"
                                                value={customerDetails.Aadhar}
                                                onChange={handlerChange}
                                                onKeyDown={blockInvalidChar}
                                                className={`form-control ${errorMsg?.Aadhar && "errorInput"}`}
                                                placeholder="Enter Aadhar Number"
                                            />
                                        </div>
                                        {errorMsg?.Aadhar ? (
                                            <small className="emailExist">{errorMsg.Aadhar}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>PAN Card *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="panCard"
                                                value={customerDetails.panCard}
                                                onChange={handlerChange}
                                                className={`form-control ${errorMsg?.panCard && "errorInput"}`}
                                                placeholder="Enter Pan Number"
                                            />
                                        </div>
                                        {errorMsg?.panCard ? (
                                            <small className="emailExist">{errorMsg.panCard}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="customer-details-form-group">
                                            <div>
                                                <label>Residential Address *</label>
                                            </div>
                                            <div>
                                                <textarea
                                                    name="residentialAddress"
                                                    value={customerDetails.residentialAddress}
                                                    onChange={handlerChange}
                                                    className={`form-control ${
                                                        errorMsg?.residentialAddress && "errorInput"
                                                    }`}
                                                    rows="3"
                                                    placeholder="Enter Residential Address"
                                                />
                                            </div>
                                            {errorMsg?.residentialAddress ? (
                                                <small className="emailExist">{errorMsg.residentialAddress}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="col adrressCheckbox">
                                        <Checkbox name="sameAddress" onChange={handlerChange} checked={customerDetails.residentialAddress&&customerDetails.residentialAddress===customerDetails.maillingAddress}>
                                            Same as Residential Address
                                        </Checkbox>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="customer-details-form-group">
                                            <div>
                                                <label>Mailing Address</label>
                                            </div>
                                            <div>
                                                <textarea
                                                    name="maillingAddress"
                                                    value={customerDetails.maillingAddress}
                                                    onChange={handlerChange}
                                                    className=""
                                                    rows="3"
                                                    placeholder="Enter Mailing Address"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails

// if(!customerDetails.lastName.length){
//     IsValid = false
// }

{
    /* <option value="Active">Active</option>
                                            <option value="Inactive">InActive</option>
                                            <option value="Deleted">Delete</option> */
}

{
    /* <form> */
}
{
    /* </form> */
}
