import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { IoIosMore } from "react-icons/io";
import houseImage from "../../Assets/images/pic1.png"
import { IoCheckmark } from "react-icons/io5";
import PMOServices from '../../Service/PMOServices';
import { useSelector } from "react-redux";
import noNotificationImg from "../../Assets/images/noNotificationImg.webp"

const Notifications = ({notifications,setNotifications}) => {
  const [activeTab, setActiveTab] = useState('All');
  const [openMoreMenu, setOpenMoreMenu] = useState(null);
  


const handleTabChange = (tab) => {
  setActiveTab(tab);
};



 
  const [isViewAll, setIsViewAll] = useState(false);
  const handleViewAll = () => {
    setIsViewAll(!isViewAll)
  }
  console.log(notifications,"comibined notifications data")

  const handleMoreMenuToggle = (index) => {
    setOpenMoreMenu((prevIndex) => (prevIndex === index ? null : index));
  };

  const updateNotificationReadService = async(notificationId)=>{
    try{
      const payload = {
        notificationId:notificationId,
        read:true,
        userId: Number(window.localStorage.getItem("user_Id"))
      }
      const response = await PMOServices.updateNotificationReadService(payload)
      console.log(response,"updateReadStatus response")
      setNotifications((prev)=>prev.map((notification)=>notification.notificationId === notificationId ?{...notification,read:true}:notification))
   

    }catch(err){
      console.error("Error updating notification read status:", err);

    }
  }

  const handleMarkAsRead = async(notificationId)=>{
updateNotificationReadService(notificationId);
setOpenMoreMenu(null);
  }

  const filteredNotifications = notifications.filter((notification) => {
    if (activeTab === "Read") return notification.read;
    if (activeTab === "Unread") return !notification.read;
    return true;
  });
  const displayedNotifications = isViewAll ? filteredNotifications : filteredNotifications.slice(0, 5);
  const handleMarkAllAsRead = async () => {
    const unreadNotifications = notifications.filter(n => !n.read);
    
    if (unreadNotifications.length === 0) return; 
    
    await Promise.all(unreadNotifications.map((notification) => 
      updateNotificationReadService(notification.notificationId)
    ));
    
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );
   

  };
  
  
  return (
    <div className={`notifications-container ${isViewAll ? 'view-all-expanded' : ''}`}>
      <div className="notifications-header">
        <h3>Notifications</h3>
        <span className="mark-all"    onClick={handleMarkAllAsRead
        
          }>Mark all as read</span>
      </div>
 
      <div className="tabs">
        <span
          className={`tab ${activeTab === 'All' ? 'active' : ''}`}
          onClick={() => handleTabChange("All")}
        >
          All <span className="badge">{notifications.length}</span>
        </span>
        <span
          className={`tab ${activeTab === 'Read' ? 'active' : ''}`}
          onClick={() => handleTabChange("Read")}
          >
          Read <span className="badge">{notifications.filter((n) => n.read).length}</span>
        </span>
        <span
          className={`tab ${activeTab === 'Unread' ? 'active' : ''}`}
          onClick={() => handleTabChange("Unread")}
         >
          Unread <span className="badge">{notifications.filter((n) => !n.read).length}</span>
        </span>
      </div>
      
     {notifications.length>0?(
       <div className="notifications-list">
        {displayedNotifications?.map((notification,index) => (
          <div key={index} className="notification-item">
            <div className="notification-content">
              <div className="content-wrapper">
                <img src={houseImage} alt="House" className="house-image" />
                <div className="text-content">
                  <h4 className="project-title">{notification.title}</h4>
                  <p className="description">{notification.description}</p>
                  <p className="description">{notification.budget}</p>
                  <p className="description">{notification.timeline}</p>
                </div>
                <div className="more-menu-container">
                  <IoIosMore
                    className="more-btn"
                    onClick={() => handleMoreMenuToggle(index)}
                  />
                  {openMoreMenu === index && (
                    <div className="more-menu">
                      <div className="menu-item"  onClick={() => handleMarkAsRead(notification.notificationId)}>
                        <span>Mark as read</span>
                        <IoCheckmark className="checkmark-icon" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ):( 
       <div className="no-notifications-container">
    <img className="no-notification-img" src={noNotificationImg} alt="No Notifications" />
    <p className="no-notification-text">There are no notifications to show</p>
  </div>
)}
     
     { notifications.length >0 && <div className="view-all">
        <a href="#" onClick={handleViewAll}>
          {isViewAll ? 'View Less' : 'View More'}
        </a>
      </div>}
    </div>
  );
};
 
export default Notifications;