import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import PMOServices from '../../../../../Service/PMOServices';
import LogContentWrapper from './logContentWrapper';



const LogsModal = ({
  logType,
  projectDetails,
  viewModal,
  logDetails,
  setlogDetails
}) => {
  console.log("log type ", logType, projectDetails);
 

  const getLogs = async (logType) => {

    console.log("body in logssss",projectDetails)
    try {
      const body = {
        projectId: projectDetails?.projectId,
        type: logType
      }


      console.log(body,"body in logssss")
      const response = await PMOServices.getLogsService(body);
      if (response.status === "success") {
        console.log("response", response.data);
        setlogDetails(response.data);
      }
      else {
        console.log("failed", response);
        toast.error("Failed to Fetch the Logs");
      }


    } catch (error) {
      console.log("error", error);
      toast.error("Failed to Fetch the Logs");
    }
  }



  useEffect(() => {
    getLogs(logType);    
  }, [logType,viewModal])
  return (
    logDetails?.map((value, index) => {
      return (
        <LogContentWrapper key={index} logDetails={value} />
      )
    })


  )
}

export default LogsModal