import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isExpandChat: boolean }>()(
  (
    _,
    {
      // isExpandChat
    }
  ) => ({
    groupInfoContainer: {
      width: '100%',
      height: '100vh',
      maxWidth: '400px',
      backgroundColor: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0.2px 2px',

      borderRadius: '8px',
      borderTopLeftRadius: '0px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      // zIndex:"999999",
      // position:"absolute",
      // marginTop:"600px",
      // right:"1px",
      left: '60px',
      top: '0px',
      padding: '10px'
    },
    groupHeaderContainer: {
      display: 'flex',
      alignItems: 'Center'
    },
    header: {
      color: '#010929',
      fontFamily: 'Montserrat',
      fontWeight:"normal",
      fontSize:"16px",
      paddingTop:"1px"
      // fontWeight:"530"
    },
    backButton: {
      marginRight: '14px',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none'
    },
    groupName: {
      fontSize: '20px',
      fontWeight: '530',
      marginTop: '10px',
      textAlign: 'center',
      marginBottom: '10px'
    },
    groupDetails: {
      textAlign: 'center',
      padding: '16px'
    },
    groupImage: {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      marginBottom: '8px'
    },
    groupDescription: {
      fontSize: '14px',
      color: '#555',
      marginBottom: '8px'
    },
    memberCount: {
      fontSize: '15px',
      color: '#70799D',
      marginTop: '10px',
      fontWeight: '530',
      marginBottom: '14px',
      marginLeft: '9px'
      // 70799D
    },
    membersList: {
      padding: '16px'
    },
    member: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px'
    },
    memberAvatar: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      marginRight: '8px'
    },
    memberName: {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    adminBadge: {
      marginLeft: '8px',
      fontSize: '12px',
      color: '#777'
    },
    footer: {
      marginTop: 'auto',
      padding: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid #ddd'
    },
    exitGroup: {
      color: '#ff0000',
      cursor: 'pointer',
      background: 'none',
      border: 'none'
    },
    reportGroup: {
      color: '#ff9900',
      cursor: 'pointer',
      background: 'none',
      border: 'none'
    },
    addMemberHeader: {
      color: '#111B21',
      fontSize: '17px',
      fontWeight: '500',
      paddingTop:"3px"
    },
    addMemberContainer: {
      display: 'flex',
      margin: '0px',
      alignItems: 'center',
      marginBottom: '6px',
      cursor: 'pointer'
    },
    plusIconContainer: {
      backgroundColor: '#00a884',
      borderRadius: '30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '33px',
      width: '33px',
      marginRight: '19px',
      marginLeft: '6px',
      padding: '4px'
    },
    groupUsersList: {
      listStyleType: 'none',
      width: '380px',
      height: 'calc(100vh - 100px)',
      overflowY: 'auto',
      padding:"0px"
    },
    searchUsersListContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    searchContainer: {
      // width:'330px',
      // backgroundColor:'#F4F5F9',
      display: 'flex',
      alignItems: 'center',
      height: '30px',
      width: '140px',
      borderRadius: '8px',
      // padding:"3px",
      // border: '3px solid #F4F5F9'
    },
    searchBar: {
      border: 'none',
      //   backgroundColor:'#F4F5F9',
      outline: 'none',
      //   paddingLeft:'7px',
      fontSize: '13px',
      width: '90px',
      marginLeft: '4px'
    },
    noUsersFound: {
      textAlign: "center",
      // color: "#999",
      fontSize: "18px",
      marginTop: "10px",
    }
  })
);
