import {Redirect, Route} from 'react-router-dom'

const ProtectedRoute = props => {
  const token = localStorage.getItem('userName')
  console.log("token inside", token);
  
  if (token === null || token === undefined ) {
    return <Redirect to="/signin" />
  }
  return <Route {...props} />
}

export default ProtectedRoute
