import Cookies from 'js-cookie';
import { CgDanger } from "react-icons/cg";
import { CiCircleAlert } from "react-icons/ci";
import { useStyles } from './RoomList.style';
import { UserTypes } from './UserTypes.type';
import RoomItem from "../RoomItem/RoomItem";
import { FixedSizeList } from "react-window";
import { RiContactsBook2Line } from "react-icons/ri";
import { useHistory } from 'react-router-dom'; // Correct import
import { MdGroups2 } from "react-icons/md";
import React, { useEffect, useState,useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useSubscription, useLazyQuery} from '@apollo/client';
import { GET_USER_BY_ID } from '../../queries/getUserById';
import { GET_USER_ROOMS } from '../../queries/getUserRooms';

import { SearchIcon } from '../../../Icons/icons';
import { decodeToken } from '../../../utils/decode';
import { GET_ACCOUNT_USERS } from "../../queries/getAccountUsers";
import { IoMdExit } from "react-icons/io";
import ContactItem from "../ContactItem/ContactItem"
import { ON_CREATE_MESSAGE_USER_ROOM_FILTER } from '../../subscriptions/onCreateMessageUserRoomFilter';
import CircularProgressComponent from '../../../LibraryComponents/CircularProgressComponent/CircularProgressComponent';
import CreateGroupOrUser from '../CreateGroupOrUser/CreateGroupOrUser';
import { setchatRoomDetails, setChatRoomId, setUserDetailsInRedux,chatOpenClicked, chatButtonClicked,setChatUnreadMessages} from  '../../../../../Features/Slices/ChatServiceReduxSlice';
import { loginLoader } from '../../../../../Features/Slices/LoginSlice';

import {GET_USER_ROOMS_BY_SEARCH } from "../../queries/getUserRoomsBySearch"
import {GET_ACCOUNT_USERS_BY_SEARCH} from "../../queries/getAccountUsersBySearch"
import {GetMenuService} from '../../../../../Service/GetMenuService'
import { toast } from 'react-toastify';



const RoomList: React.FC<UserTypes> = ({ chatDetailClicked, isExpandChat,
  isChatBtnClicked,
  setIsChatBtnClicked,
 handleExpandChat,
 setIsExpandChat,
 isChatDetailClicked,
 setIsChatDetailClicked,

  
 }) => {
  const [mainUser, setMainUser] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<any | null>(null);
  const [roomDetails, setRoomDetails] = useState<any[]>([]);
  const [allRoomDetails, setAllRoomDetails] = useState<any[]>([]);
  const [selectedRoomId, setSelectedRoomId] = useState<any | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [contactSearchQuery, setContactSearchQuery] = useState<string>('');
  const [isDelayedLoading, setIsDelayedLoading] = useState(true);
  const [footerIconsBackGround, setFooterIconsBackGround] = useState("groups");
  const account_id = '30012025';
  const [accountAllContacts, setAccountAllContacts] = useState<any[]>([]);
  const [accountAllContactsCopy, setAccountAllContactsCopy] = useState<any[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const chatRoomDetails = useSelector((state: any) => state.chatRoomDetails || [])
  const chatRoomDetails = useSelector(
    (state: any) => state.chatRoomDetails,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next) // Prevents re-renders if data is unchanged
  );

let rooms
console.log(rooms,"roomsssssssssssssss")
  // const isChatBtnClicked=useSelector((state:any)=>state.ChatServiceReduxSlice.isChatBtnClicked)
  const [userId,setUserId]=useState<string | null>(null)

  
  const timeoutRefOfRooms = useRef<ReturnType<typeof setTimeout> | null>(null);
const timeoutRefOfContacts = useRef<ReturnType<typeof setTimeout> | null>(null);


  const { classes } = useStyles({ isExpandChat });
  console.log(classes,"roomlist classess")

  const dispatch = useDispatch()
  //console.log(isDelayedLoading);
  //console.log(userDetails);
  //console.log(selectedRoomId);



  type RowRendererProps = {
    index: number;
    style: React.CSSProperties;
  };


   
 


  const {  data: userData } = useQuery(GET_USER_BY_ID, { variables: { user_id: userId } });
  const { loading: roomsLoading,  data: roomsData, refetch: refetchRoomData ,error} = useQuery(GET_USER_ROOMS, { variables: { user_id:  userId} });
  const { data: roomsSubscriptionData, } = useSubscription(ON_CREATE_MESSAGE_USER_ROOM_FILTER, { variables: { user_id: mainUser } });
  const { data: accountUserData } = useQuery(GET_ACCOUNT_USERS, { variables: { account_id }, skip: !account_id })

  let rooms1
  
//console.log("error in roomsList",error?.message);  
const [getUserRoomsBySearch] = useLazyQuery(GET_USER_ROOMS_BY_SEARCH);
const [getUserContactsBySearch] = useLazyQuery(GET_ACCOUNT_USERS_BY_SEARCH);

console.log("userData",userData)      
  useEffect(() => {
    const userId1=localStorage.getItem('user_Id')
    console.log("userId1",userId1)
    setUserId(userId1)
  
  },[userId]);
  useEffect(() => {
    if (userId && userData) {
      refetchRoomData();
      //console.log("mainUser", mainUser)
    }
  }, [ userData, refetchRoomData, roomsSubscriptionData,userId]);

  useEffect(() => {
    if (roomsSubscriptionData) {
      refetchRoomData();
    }
  }, [roomsSubscriptionData]);
// console.log("roomsError",roomsError?.message);
  useEffect(() => {
    if (userData && userData.getUserById) {
      setUserDetails(userData.getUserById);
      dispatch(setUserDetailsInRedux({ userDetails: userData.getUserById }))
    }
    if (accountUserData?.GetAccountUsers) {
      setAccountAllContacts(accountUserData.GetAccountUsers)
      setAccountAllContactsCopy(accountUserData.GetAccountUsers)
    }

  }, [userData, accountUserData]);


  //console.log("accountAllContacts fromn room List", accountAllContacts)

  useEffect(() => {
    if (roomsData && roomsData.getUserRooms) {
     const  rooms = roomsData.getUserRooms.userRooms;
      const roomsForRender = rooms.filter((eachRoom: { room_details: null; }) => eachRoom.room_details !== null)
      // console.log("roomsss",rooms)
      dispatch( setchatRoomDetails({chatRoomDetails:roomsForRender || []}))
      setRoomDetails(roomsForRender)
      setAllRoomDetails(roomsForRender);
      

    }
  }, [roomsData]);


  // Delay handling for loading spinner
  useEffect(() => {
    if (roomsLoading) {
      const timer = setTimeout(() => {
        setIsDelayedLoading(false);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setIsDelayedLoading(false);
    }
  }, [roomsLoading]);




  useEffect(() => {
    console.log("Chat Room Details:", chatRoomDetails);
  }, [chatRoomDetails]);


  // if (userError) return <div>Error: {userError.message}</div>;
  // if (roomsError) return <div>Error: {roomsError.message}</div>;

  const handleRoomSelect = (room: any) => {
    dispatch(setChatRoomId({ chatRoomId: room.room_id }))
    setSelectedRoomId(room.room_id);
    console.log("Room Select", room.room_id)
    chatDetailClicked?.(room, userData);

    refetchRoomData();

  };

  const debouncedSearchForRooms = async (query: string) => {
    //console.log("searchInput from roomList ====>", query)
    const lowerCaseQuery = query.toLowerCase();

    if (query.trim()) {
      try {
        const response = await getUserRoomsBySearch({
          variables: {
            user_id: userId,
            userSearchInput: lowerCaseQuery,
          },
        });
        //console.log("Room Search API response ===>", response.data.getUserRoomsBySearch);
        const userRoomsFromResponse = response.data?.getUserRoomsBySearch?.userRooms || []; 
        //console.log("userRoomsForRender from RomList: ", userRoomsFromResponse)
        setRoomDetails(userRoomsFromResponse);
          
      } catch (error) {
        console.error("Error fetching user rooms by search:", error);
      }
    } else {
      setRoomDetails(allRoomDetails);
    }
  
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    if (timeoutRefOfRooms.current) {
      clearTimeout(timeoutRefOfRooms.current);
    }
    setSearchQuery(query);
    timeoutRefOfRooms.current = setTimeout(() => {
      debouncedSearchForRooms(query); 
    }, 500);
    
  };

  const debouncedSearchForContacts = async (contactQuery: string) => {
    //console.log("contact search input: ", contactQuery)
    const lowerCaseContactQuery = contactQuery.toLowerCase();
    // const filteredContacts = accountAllContactsCopy.filter((contact: any) => {
    //   const contactName = contact.user_details?.user_name.toLowerCase() || '';
    //   return contactName.includes(lowerCaseContactQuery)
    // });
    // console.log("filterted contacts from roomList : ", filteredContacts)
    // setAccountAllContacts(filteredContacts)

    if (contactQuery.trim()) {
      try {
        const response = await getUserContactsBySearch({
          variables: {
            account_id: account_id,
            userSearchInput: lowerCaseContactQuery,
          },
        });
        console.log("Search API response of Contacts ===>", response.data);
        const userContactsFromResponse = response.data?.GetAccountUsersBySearch || [];
        setAccountAllContacts(userContactsFromResponse)
      } catch (error) {
        console.error("Error fetching user Contacts by search:", error);
      }
    } else {
      setAccountAllContacts(accountAllContactsCopy)
    }
      
    
  }
  //console.log("accountAllContactsCopy from rooms List==========> ", accountAllContactsCopy)
  const handleContactSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const contactQuery = event.target.value;
    if (timeoutRefOfContacts.current) {
      clearTimeout(timeoutRefOfContacts.current);
    }
    setContactSearchQuery(contactQuery);
    timeoutRefOfContacts.current = setTimeout(() => {
      debouncedSearchForContacts(contactQuery); 
    }, 500);
    

  }


  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const changeFooterBackGround = (event: React.MouseEvent<HTMLButtonElement>) => {
    const iconName = event.currentTarget.value;
    setFooterIconsBackGround(iconName)


  }


    
const handleChatClose=()=>{


   

  setIsChatBtnClicked(false)
console.log(isExpandChat,"expandinggggg")
// setIsChatDetailClicked(false)

if(isExpandChat){
  handleExpandChat()
  setIsChatBtnClicked(false)
  
}

}

  return (
    <div className={classes.listDetailsMainContainer} 
    // style={{ backgroundColor: 'white', padding: '10px' }}
    >
      
      {roomsLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgressComponent />
        </div>
      ) : (
        <div>
          {footerIconsBackGround === "groups" ? (
            <>
              <div className={classes.recentChatsContainer}>
                <p className={classes.recentChatsName}>Recent Chats</p>
              
                <p className={classes.plusIcon} onClick={handleOpenPopup}>
                  +
                </p>

  <p  onClick={handleChatClose}    className={classes.exitIcon}>
  <IoMdExit 

   style={{fontSize:"20px"}}
   
/>
     </p> 



             
              </div>
              <CreateGroupOrUser
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                isExpandChat={isExpandChat}
                userId={userId}
                refetchRooms={refetchRoomData}
              />
                    <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgb(244, 245, 249)',
              padding: '5px 10px',
              borderRadius: '20px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              margin:"10px"
              
              
            }}
          >
            <SearchIcon
            //  style={{ marginRight: '10px' }}
              />
            <input
              type="text"
              placeholder="Search..."
              style={{
                border: '0px',
                outline: 'none',
                fontSize: '16px',
                width: '100%',
                backgroundColor: 'rgb(244, 245, 249)',
              }}
              value={searchQuery}
              onChange={handleSearch}
           
            />
          </div>

       
              {roomDetails && roomDetails.length > 0 ? (
                <div>   
                <FixedSizeList
           height={720}
                  itemCount={roomDetails.length}
                  itemSize={70}
                  width="380px"
                  style={{ overflowX: "hidden" }}
                  className={classes.fixedSizeList}
                >

                  {({ index, style }: RowRendererProps) => (
                    roomDetails[index].room_details ? (
                      <div style={{ ...style, paddingLeft: "10px", paddingRight: "10px" }}>
                        <RoomItem
                          key={roomDetails[index].room_id}
                          room={roomDetails[index]}
                          handleRoomSelect={handleRoomSelect}
                        />
                      </div>
                    ) : null
                  )}
                </FixedSizeList>
                </div>
              ) : searchQuery ? (
                <div className={classes.noRoomsContainer}
                style={{height:"720px"}}>
                  {/* <div className={classes.noRoomDangerIcon}>
                    <CgDanger  style={{height:"13em",width:"2em",padding:"0px"}} />
                  </div> */}
                  <div>
                    <p className={classes.noRoomName} style={{color:"red"}}>No Room Found!</p>
                  </div>
                </div>
              ) : null}
            </>
          ) : footerIconsBackGround === "contacts" ? (
            <>
              <div className={classes.recentChatsContainer}>
                <p className={classes.recentChatsName}>Contacts</p>
                <p className={classes.plusIcon} onClick={handleOpenPopup}
                  style={{backgroundColor: 'rgb(244, 245, 249)'}}>
                  +
                </p>
  
                 <p className={classes.plusIcon} onClick={handleChatClose}
                >
                 <IoMdExit 
                 style= {{fontSize:"20px"}} 
                 />
                    </p>
           
               
              </div>
              <CreateGroupOrUser
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                isExpandChat={isExpandChat}
                userId={mainUser}
                refetchRooms={refetchRoomData}
              />
                          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgb(244, 245, 249)',
              padding: '5px 10px',
              borderRadius: '20px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              margin:"10px"
              
            }}
          >
            <SearchIcon
            //  style={{ marginRight: '10px' }} 
             />
            <input
              type="text"
              placeholder="Search..."
              style={{
                border: '0px',
                outline: 'none',
                fontSize: '16px',
                width: '100%',
                backgroundColor: 'rgb(244, 245, 249)',
              }}
              value={contactSearchQuery}
              onChange={handleContactSearch}
            
            />
          </div>
              <div >
                {accountAllContacts && accountAllContacts.length > 0 ?
                  (<FixedSizeList
                    // height={isExpandChat ? window.innerHeight * 0.87 - 100 : window.innerHeight * 0.92 - 100}
                    height={720}
                    itemCount={accountAllContacts.length}
                    itemSize={70}
                    width="380px"
                    style={{overflowX:"hidden",}}
                    className={classes.fixedSizeList}
            
                  >
                    {({index, style}:RowRendererProps) => (
                      <div style={{...style, paddingLeft:"10px",}}>
                      <ContactItem key={accountAllContacts[index].user_id} contact={accountAllContacts[index]} />
                      </div>
                    )}
                  </FixedSizeList>) : contactSearchQuery ? (<div className={classes.noRoomsContainer}
                  style={{height:"720px"}}>
                    {/* <div className={classes.noRoomDangerIcon}>
                      <CgDanger style={{height:"13em",width:"2em"}}/>
                    </div> */}
                    <div>
                      <p className={classes.noRoomName} style={{color:"red"}}>No Contact Found!</p>
                    </div>
                  </div>) : null}
              </div>
            </>
          ) : null}

           <div className={classes.footerContainer}>
            <button
              value="groups"
              onClick={changeFooterBackGround}
              className={
                footerIconsBackGround === "groups"
                  ? classes.groupIconsCss2
                  : classes.groupIconCss
              }
            >
              <MdGroups2  style={{fontSize:"40px"}}/>
            </button>
            <button
              value="contacts"
              onClick={changeFooterBackGround}
              className={
                footerIconsBackGround === "contacts"
                  ? classes.contactsCss2
                  : classes.contactsCss
              }
            >
              <RiContactsBook2Line   style={{fontSize:"40px"}}/>
            </button>
          </div> 
        </div>
      )}
    </div>
  );


};

export default RoomList;
