import { Avatar, Button, Card, Divider, Modal, Progress, Timeline, Select , notification} from 'antd';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { imageAssets } from '../../../../Assets/AssetsConstants/AssetsConstants';
import ImageModal from './ImageModal/ImageModal';
import "./ProjectDetails.css";
import CustomSteps from './Steps/CustomSteps';
import { MdModeEditOutline, MdOutlineEdit } from "react-icons/md";
import { AiOutlineArrowLeft,AiOutlineClose,AiOutlineCheck } from "react-icons/ai";
import PMOServices from '../../../../Service/PMOServices';
import { toast } from 'react-toastify';
import EditProjectDetails from './EditProjectDetails/EditProjectDetails';
import ScheduleModal from './SchedulesModal/ScheduleModal';
import moment from 'moment';
import PCServices from '../../../../Service/PCServices';
import { useHistory } from "react-router-dom"
import { VscInfo } from 'react-icons/vsc';
import Logs from './LogsModal/logsModal';
// import GoogleMapReact from 'google-map-react';
// import Marker from './Marker/marker';
import { MapContainer, TileLayer, useMap, Popup, Marker, Circle } from 'react-leaflet';
import "leaflet/dist/leaflet.css";


import { BsBoxArrowUpRight } from "react-icons/bs";
import {userIdContext} from '../../../../Components/Signin'
import { useDispatch, useSelector } from 'react-redux';

import { SOCKET_EVENT } from '../../../../Features/Saga/socketSaga';
// import usecontex from '../../../../Components/Signin/userContext11'1

import L from "leaflet";
import PlusIconWithLength from '../../../../Components/PlusIconwithLength/plusIconWithLength';
import { io } from "socket.io-client";
const SOCKET_URL = process.env.REACT_APP_API_URL ;
const socket = io(SOCKET_URL, { transports: ["polling","websocket"] ,
  reconnectionAttempts: 3,
  timeout: 10000
 });
const {Option} = Select
let UID2 = localStorage.getItem('user_Id');
console.log(UID2,"idddddddddddddddddddddd")

const iconFunction = (coordinates) => {
  return L.icon({
    iconUrl: imageAssets.housemarker,
    iconSize: coordinates
  })

}
const testData = {
  milestoneTitle: "Servey",
  milestoneStatus: "Pending",
  milestoneStatusPercentage: "10",
  milestondId: 1,
  milestoneEtc: "2022-09-10"
}


const ProjectDetails = (
  {
    selectedProject,
    screenType,
    showOngoingProject,
    activeKey,
    handleTabs,
    handleProjectName
  }
) => {
  console.log('screentype::', activeKey, screenType, showOngoingProject, selectedProject);

  const [viewModal, setViewModal] = useState(false);
  const [viewPMModal, setViewPMModal] = useState(false);
  const [viewPCModal, setViewPCModal] = useState(false);
  const [newPM, setNewPM] = useState();
  const [newPC, setNewPC] = useState();
  const [logHistory, setLogHistory] = useState(false);
  const [selectedPM, setSelectedPM] = useState({ userId: null, firstname: "" });
  const [selectedPC, setSelectedPC] = useState({ userId: null, firstname: "" });
  const [typeOfComponent, setTypeOfComponent] = useState("");
  const [projectDetails, setProjectDetails] = useState({});
  const [allPMs, setAllPMs] = useState({});
  const [allocationLogs, setAllocationLogs] = useState();
  const [pcLogs, setPCLogs] = useState([]);
  const [pendingPCLogs, setpendingPCLogs] = useState([]);  
  const [pmLogs, setPMLogs] = useState([]);
  const [pendingPMLogs, setpendingPMLogs] = useState([]);  
  const [scheduleInfo, setScheduleInfo] = useState({
    preStartDate: null,
    preEndDate: null,
    conStartDate: null,
    conEndDate: null,
    preProjectDuration: "",
    conProjectDuration: "",
    preConstructionPre: 0
  })
  const [projectData, setProjectData] = useState({});
  const [projectName, setProjectName] = useState();
  const [milestoneDetails, setMilestoneDetails] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const [blockedMilestoneDetails, setBlockedMilestoneDetails] = useState({
    // miestoneTitle: '',
    // miestoneId: '',
    // milestoneStatus: '',
    // milestoneStatusPercentage: '',
    // milestoneEtc: '',
    // blockDate: ''

  });
  const history=useHistory();
  const [upcomingMilestonDetails, setUpcomingMilestonDetails] = useState({});
  const [incomingMilestonDetails, setIncomingMilestonDetails] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [description, setDescription] = useState(
    [
      { preChangeValidator: false, preDescription: "" },
      { consChangeValidator: false, consDescription: "" }
    ]
  )
  const [logType, setLogType] = useState("");
  const [getPrjDetails, setGetPrjDetails] = useState(false)
  const [images, setImages] = useState();
  const [logDetails, setlogDetails] = useState([]);

  const { logindata, message } = useSelector((state) => state.Login);
    const dispatch = useDispatch();
        const socketData = useSelector((state) => state.socket.data);

  const userID = logindata
console.log("USERID inside the project:", userID);

  console.log("incomingMilestonDetails---", Object.keys(blockedMilestoneDetails).length);

  console.log("projectDetails----", projectDetails);
  console.log("projectDetailsPM----", allPMs);


  const handleReset = () => {
    setScheduleInfo(
      {
        preStartDate: null,
        preEndDate: null,
        conStartDate: null,
        conEndDate: null,
        preProjectDuration: "",
        conProjectDuration: ""

      }
    )
    setDescription(
      [
        { preChangeValidator: false, preDescription: "" },
        { consChangeValidator: false, consDescription: "" }
      ]
    )
    // setlogDetails([]);
  }
  const closeModal = () => {
  
    handleReset();
    
    setViewModal(false);
  }
  const handleCancel = (projectId) => {
    console.log("projectId----", projectId);
    getProjectDetails(projectId);
    closeModal();

  }

  const getProjectAllocationLogs = async (projectId) => {
    const body = {
      projectId: projectId
    }
    const response = await PMOServices.getAllocationLogsHistory(body);
    console.log("Response for projectAllocationLogs: ", response);
    if (response.status ==="success") {
      console.log("Response got status of 200!!..");
      setAllocationLogs(response.projectData)
      setPMLogs(response.projectData.pm_logs);
      setPCLogs(response.projectData.pc_logs);
      setpendingPMLogs(response.projectData.pending_pm_log);
      setpendingPCLogs(response.projectData.pending_pc_log);
    }
  }

  const wrapClickLogs = (pId) => {
    handleLogView();
    getProjectAllocationLogs(pId);
  }

  console.log("Allocated logs: ", allocationLogs);
  

  console.log("milestone details", blockedMilestoneDetails, upcomingMilestonDetails, incomingMilestonDetails);
  const COMPONENTSENUM = {
    "ProjectImages":
      <ImageModal
        images={projectDetails?.imageDetails}
      />,
    "EditProjectDetails":
      <EditProjectDetails
        projectData={projectData}
        setProjectData={setProjectData}
        handleCancel={handleCancel}
        screenType={screenType}
        setGetPrjDetails={setGetPrjDetails}
        images={images}
        setImages={setImages}
      />,
    "ProjectSchedule":
      <ScheduleModal
        projectDetails={projectDetails}
        handleCancel={handleCancel}
        scheduleInfo={scheduleInfo}
        setScheduleInfo={setScheduleInfo}
        spinner={spinner}
        setSpinner={setSpinner}
        description={description}
        setDescription={setDescription}
      />,
    "Logs":
      <Logs
        logType={logType}
        projectDetails={projectDetails}
        viewModal={viewModal}
        logDetails={logDetails}
        setlogDetails={setlogDetails}
      />
  }



  const handleView = (type, logType) => {
    console.log("type", type);
    if (type === "ProjectSchedule") {
      setScheduleInfo(
        {
          preStartDate:
            projectDetails?.preConstructionStartDate ?
              moment(projectDetails?.preConstructionStartDate).format("YYYY-MM-DD") :
              null,
          preEndDate: projectDetails?.preConstructionEndDate ?
            moment(projectDetails?.preConstructionEndDate).format("YYYY-MM-DD") :
            null,
          conStartDate: projectDetails?.constructionStartDate ?
            moment(projectDetails?.constructionStartDate).format("YYYY-MM-DD") :
            null,
          conEndDate: projectDetails?.constructionEndDate ?
            moment(projectDetails?.constructionEndDate).format("YYYY-MM-DD") :
            null,
          conProjectDuration: projectDetails?.projectDuration,
          preProjectDuration: projectDetails?.preProjectDuration,
          preConstructionPre: null

        }
      )
      setTypeOfComponent(type);
      setViewModal(true);
    }
    else if (type === "EditProjectDetails") {
      setProjectData(projectDetails);
      setTypeOfComponent(type);
      setViewModal(true);
    }
    else if (type === "ProjectImages" && projectDetails?.imageDetails) {
      setTypeOfComponent(type);
      setViewModal(true);
    }
    else if (type === "Logs") {
      closeModal()
      setLogType(logType);
      setTypeOfComponent(type);
      setViewModal(true);
    }



  }
  const getProjectDetails = async (projectId) => {
  localStorage.setItem("tab",'pmo')
    let body;
    if(screenType==="PMO"){
       body = {
        projectId: projectId,
        user_id: 2
      }

    }
    else{
       body = {
        projectId: projectId,
        user_id: Number(UID2)
      }

    }
      
    
    
    // console.log("bodyOF: " + JSON.stringify(body));
    
    const response = await PMOServices.getProjectDetailsByProjectId(body);
    console.log("prj-response---", response);
    if (response.status === "success") {
      let milestonesDetails = [];
      handleProjectName(response.projectData[0].projectName);
      const localProjectDetails = {
        ...response.projectData[0],
        allocationdetails: [
          { "name": response.projectData[0].pmName, "type": "Project Manager" },
          { "name": response.projectData[0].pmoName, "type": "Project Coordinator" },
          { "name": response.projectData[0].salesExecutiveName, "type": "Sales Executive" },
          { "name": response.projectData[0].salesCoordinatorName, "type": "Sales Coordinator" }
        ]
      }
      setNewPM(response.projectData[0].pmName)
      setNewPC(response.projectData[0].pmoName)

      response?.projectData?.map((value) => {
        milestonesDetails.push({
          milestoneId: value.milestoneId,
          milestoneName: value.milestoneName,
          milestoneStatus: value.milestoneStatus,
          milestoneEtc: value.milestoneEtc
        })
      })
      console.log("milstone details", milestonesDetails);
      console.log("localProjectDetails----", localProjectDetails);
      setMilestoneDetails(milestonesDetails);
      setProjectDetails(localProjectDetails);
      // setNewPM(localProjectDetails?.allocationdetails.pmName);
      setAllPMs(localProjectDetails);
      setImages(localProjectDetails?.imageDetails)
      setGetPrjDetails(false)
    }
    else {
      toast.error("Failed to Fetch Project Details");
    }
  }
  console.log("projectDetails----", projectDetails);
  const handleTitle = () => {
    if (typeOfComponent === "ProjectImages") {
      return "Project Images"
    }
    else if (typeOfComponent === "EditProjectDetails") {
      return "Project Details"
    }
    else if (typeOfComponent === "ProjectSchedule") {
      return "Schedules"
    }
    else if (typeOfComponent === "Logs") {
      if (logType === "pre") {
        return "Pre Construction Event Logs"
      }
      else {
        return "Construction Event Logs"
      }

    }
  }

  const handleImageDisplay = (imageDetails) => {
    console.log("images tags", imageDetails);
    if (imageDetails?.length) {
      if (imageDetails?.length === 1 && !imageDetails[0].dataURL._image_urls) {
        return ([])
      }
      else {
        return ([...imageDetails])
      }

    }
    else {
      // return (new Array({ dataURL: "" }, { dataURL: "" }, { dataURL: "" }, { dataURL: "" }))
      return ([])
    }
  }

  useEffect(() => {
    if (activeKey === 'Details') {
      getOngoingProjectDetails()
      // getProjectDetails(selectedProject)
      
    }
  }, [activeKey])

  useEffect(() => {
    console.log("getPrjDetails--", getPrjDetails);
    if (getPrjDetails) {
      getProjectDetails(selectedProject);
    }

  }, [getPrjDetails])

  useEffect(() => {


    getProjectDetails(selectedProject);
    // wrapClickLogs(selectedProject)  ;

  }, [])

 


  const getOngoingProjectDetails = async (body) => {
    try {
      console.log("body::::", body);
      const payload = {
        projectId: selectedProject
      }
      const response = await PCServices.getOngoingProjectData(payload);
      console.log("ongoo---response", response);
      if (response && response?.getBlockResumeDesc) {
        const blockedMilestone = response?.blcokResummeDescData.filter(item => (
          (item.isApproved === 5 && item.isBlocked === 4) || (item.isApproved === 6 && item.isBlocked === 3)
          // ([5,6].includes(item.isApproved) && [3,4].includes(item.isBlocked))
        ));
        if (blockedMilestone) {
          console.log("item-in-bll-", blockedMilestone);
          setBlockedMilestoneDetails(blockedMilestone)
          //   miestoneTitle: blockedMilestone.milestoneTitle,
          //   miestoneId: blockedMilestone.milestoneId,
          //   milestoneStatus: blockedMilestone.milestoneStatus,
          //   milestoneStatusPercentage: blockedMilestone.milestoneStatusPercentage,
          //   milestoneEtc: blockedMilestone.milestoneEtc,
          //   isApproved: blockedMilestone.isApproved
          // })
        }
        else {
          setBlockedMilestoneDetails([])
        }
        const upcomingMilestone = response?.blcokResummeDescData.find(item =>
        // (item.milestoneStatus === "Pending")
        (((item.isBlocked === 0 && item.isApproved === 0) ||
          (item.isBlocked === 1 && item.isApproved === 5) ||
          (item.isBlocked === 4 && item.isApproved === 6) ||
          (item.isBlocked === 3 && item.isApproved === 5)) &&
          (item.milestoneStatus === "Pending")
        )
        )
        if (upcomingMilestone) {
          console.log("item-in--", upcomingMilestone);
          setUpcomingMilestonDetails({
            miestoneTitle: upcomingMilestone.milestoneTitle,
            miestoneId: upcomingMilestone.milestoneId,
            milestoneStatus: upcomingMilestone.milestoneStatus,
            milestoneStatusPercentage: upcomingMilestone.milestoneStatusPercentage,
            milestoneEtc: upcomingMilestone.milestoneEtc,
            isApproved: upcomingMilestone.isApproved

          })
        }
        const ongoingMilestone = response?.blcokResummeDescData.filter(item =>
        // (item.milestoneStatus === "Running")
        (!((item.isApproved === 5 && item.isBlocked === 4) || (item.isApproved === 6 && item.isBlocked === 3))
          && (item.milestoneStatus === "Running")
        )
        )
        if (ongoingMilestone) {
          console.log("item-in--", ongoingMilestone);
          setIncomingMilestonDetails(ongoingMilestone)

        }

      }
    } catch (err) {
      console.log("err--", err);
    }
  }
  console.log("typeof component", milestoneDetails);
  const getUpcomingMilestoneStatusDetails = () => {
    console.log("jhghjg");
    return (
      <Timeline>
        <Timeline.Item color={upcomingMilestonDetails.milestoneStatus === null && "#FF0000" ||
          upcomingMilestonDetails.milestoneStatus === "Completed" && "#52C41A" ||
          upcomingMilestonDetails.milestoneStatus === "Running" && "#3590FF" ||
          upcomingMilestonDetails.milestoneStatus === "pending" && "#E3E4E8"
        } style={{ marginLeft: "10px" }}>
          <div className={`projectMileStoneCard`}>
            {/* <div className={`mileStones ${(item.milestoneStatusId === 3 || item.milestoneId === mIdStatusChange && disabled) && `milestone-block`}`}
           onClick={() => { item.milestoneStatusId !== 3 ? getMilestoneTasks(item.milestoneId) : handlerMilestone(item.milestoneId) }} 
          onClick={() => getMilestoneTasks(item.milestoneId)}
        > */}
            <div className='row'>
              <div className='col-sm-5 milestoneInfo'>
                <div className=''>
                  <span>{upcomingMilestonDetails.miestoneTitle}</span>
                  <span>{upcomingMilestonDetails.milestoneStatus}</span>
                  <div><span>{upcomingMilestonDetails.milestoneStatusPercentage || 0}%</span></div>
                  <Progress size="small" percent={upcomingMilestonDetails.milestoneStatusPercentage || 0} />
                </div>
              </div>
              <div className='col-sm-3 milestoneNum'>
                <div className=''>
                  <span>Milestone</span>
                  {/* {console.log("milestoneId-:-",upcomingMilestonDetails)} */}
                  <span>{upcomingMilestonDetails.miestoneId}</span>
                </div>
              </div>
              <div className='col-sm-4 milestoneEtc'>
                <div className=''>
                  <span>ETC</span>
                  <div className='milestoneDate'>
                    <span>{upcomingMilestonDetails.milestoneEtc ? moment(upcomingMilestonDetails.milestoneEtc).format('D') : 'N/A'}</span>
                    <div>
                      <span>{upcomingMilestonDetails.milestoneEtc && moment(upcomingMilestonDetails.milestoneEtc).format('MMM')}</span>
                      <span>{upcomingMilestonDetails.milestoneEtc && moment(upcomingMilestonDetails.milestoneEtc).format('YYYY')}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </Timeline.Item>

      </Timeline>
    )
  }
  const ongoingMilestoneStatusDetails = () => {
    return (
      // incomingMilestonDetails.map(item => (

      incomingMilestonDetails?.length &&
      <Timeline>
        <Timeline.Item color={incomingMilestonDetails[0].milestoneStatus === null && "#FF0000" ||
          incomingMilestonDetails[0].milestoneStatus === "Completed" && "#52C41A" ||
          incomingMilestonDetails[0].milestoneStatus === "Running" && "#3590FF" ||
          incomingMilestonDetails[0].milestoneStatus === "pending" && "#E3E4E8"
        } style={{ marginLeft: "10px" }}>
          <div className={`projectMileStoneCard`}>
            <div className='row'>
              <div className='col-sm-5 milestoneInfo'>
                <div className=''>
                  <span>{incomingMilestonDetails[0].milestoneTitle}</span>
                  <span>{incomingMilestonDetails[0].milestoneStatus}</span>
                  <div><span>{incomingMilestonDetails[0].milestoneStatusPercentage || 0}%</span></div>
                  <Progress size="small" percent={incomingMilestonDetails[0].milestoneStatusPercentage || 0} />
                </div>
              </div>
              <div className='col-sm-3 milestoneNum' >
                <div className=''>
                  <span >Milestone</span>
                  <span >{incomingMilestonDetails[0].milestoneId}</span>
                </div>
              </div>
              <div className='col-sm-4 milestoneEtc'>
                <div className=''>
                  <span>ETC</span>
                  <div className='milestoneDate'>
                    <span>{incomingMilestonDetails[0].milestoneEtc ? moment(incomingMilestonDetails[0].milestoneEtc).format('D') : 'N/A'}</span>
                    <div>
                      <span>{incomingMilestonDetails[0].milestoneEtc && moment(incomingMilestonDetails[0].milestoneEtc).format('MMM')}</span>
                      <span>{incomingMilestonDetails[0].milestoneEtc && moment(incomingMilestonDetails[0].milestoneEtc).format('YYYY')}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </Timeline.Item>

      </Timeline>


    )
  }

  const [modal, setModal] =Modal.useModal();
  // const []
  const otherPMOptions = allPMs;
  console.log("OtherS: " + allPMs);
  console.log("Other PMs: ", projectDetails.otherPMs);
  const [pmNotify, pmcontextHolder] = notification.useNotification();
  const [pcNotify, pccontextHolder] = notification.useNotification();
  
  const assignNewPM = async () => {
    if (!selectedPM.userId) {
      alert("Please select a Project Manager.");
      return;
    }
    console.log( "Assigned new PM: " +selectedPM.firstname);
    setNewPM(selectedPM.firstname);
    pmNotify.info({
      message: `Reallocated ${selectedPM.firstname} as a Project Manager`,
      placement: 'bottomRight',
    className: 'ant-notification-notice-message',
    style : {backgroundColor:'green', borderRadius: 6,fontSize: '20px'},
    closeIcon: false,
      duration: 3,
      icon: <AiOutlineCheck style={{ fontSize: '20px',color:"white"}}/>

    })
    
    const body = {
      project_id: projectDetails.projectId,
      pm_id: selectedPM.userId,
      newPM:selectedPM?.firstname,
      oldPM:pendingPMLogs?.pm_name,
      projectName:projectDetails?.projectName,
      userID:localStorage.getItem("user_Id")
    }

    console.log("reallocation pm details",body)
    const response = await PMOServices.updatePMOfProject(body);
  }

  const assignNewPC = async () => {
    if (!selectedPC.userId) {
      alert("Please select a Project Coordinator.");
      return;
    }
    console.log( "Assigned new PC: " +selectedPC);
    setNewPC(selectedPC.firstname);
    pcNotify.info({
      message: `Reallocated ${selectedPC.firstname} as a Project Coordinator`,
    closeIcon: false,
    placement: 'bottomRight',
    style : {backgroundColor:'green', borderRadius: 6,color:"white"},
      duration: 3,
      icon: <AiOutlineCheck style={{ fontSize: '20px'}}/>
    })
    const body = {
      project_id: projectDetails.projectId,
      pc_id: selectedPC.userId,
      newPC:selectedPC?.firstname,
      oldPC:pendingPCLogs?.pc_name,
      projectName:projectDetails?.projectName,
      userId:window.localStorage.getItem('user_Id')
    }
    const response = await PMOServices.updatePCOfProject(body);

    console.log('logedINuserId',body.userId);
  }

  const handlePMChange = (ele) => {
    const parsedValue = JSON.parse(ele);
    console.log("Selected value: ", parsedValue);
    setSelectedPM(parsedValue);
  }

  const handlePCChange = (ele) => {
    const parsedValue = JSON.parse(ele);
    console.log("Selected value: ", parsedValue);
    setSelectedPC(parsedValue);
  }

  const handleLogView = () => {
    setLogHistory(!logHistory);
  };
  // manage pm change modal
  const confirm =() => {
    modal.confirm({
      title:"Reallocate",
      okText:"Reallocate"
    })
  }


  useEffect(() => {
    getProjectAllocationLogs(selectedProject);
  }, [viewPMModal, viewPCModal]);

    useEffect(() => {
        socket.on("onAllocateProject", (data) => {
          console.log("Ticket milestoneProgress drawings data", data);
           dispatch({ type: SOCKET_EVENT, payload: data }); 
        });
  
        return () => {
          socket.off("onAllocateProject");
        };
      }, [dispatch]);

  return (
    <div>
      <div className="projectDetails">
        <div className='contentBody row'>
          <div className='col-sm-5 constructionInfo'>
            <div className="scheduleInfo">
              <div className='prescheduleInfo'>
                <h4>Construction Schedule
                  <span>({projectDetails?.projectDuration}Months)</span>
                  <span>{projectDetails?.consLogs === "yes" &&
                    <VscInfo size={20} color="red" onClick={() => handleView("Logs", "cons")} />}
                  </span>
                </h4>
                <CustomSteps
                  start={projectDetails?.constructionStartDate}
                  end={projectDetails?.constructionEndDate}
                  percentage={projectDetails?.constructionPercent}
                />
              </div>
              <div className='prescheduleInfo'>
                <h4>Pre-Construction
                  <span>({projectDetails?.preProjectDuration}Months)</span>
                  <span>{projectDetails?.preLogs === "yes" &&
                    <VscInfo size={20} color="red" onClick={() => handleView("Logs", "pre")} />}
                  </span>
                </h4>
                <CustomSteps
                  start={projectDetails?.preConstructionStartDate}
                  end={projectDetails?.preConstructionEndDate}
                  percentage={projectDetails?.preConstructionPre}
                />
              </div>
              {incomingMilestonDetails.length > 0 &&
                <div className='ongoing-milestone'>
                  <h4 style={{ marginLeft: 12 }}>
                    Ongoing Milestone
                    {(incomingMilestonDetails.length - 1) > 0 &&
                      <PlusIconWithLength
                        length={incomingMilestonDetails.length - 1}
                        handler={() => handleTabs("BuildingProgress")}
                        screenType={screenType}
                      />
                    }
                  </h4>
                  <div className='ongoing'>
                    {ongoingMilestoneStatusDetails()}
                  </div>
                </div>
              }
              {Object.keys(upcomingMilestonDetails).length !== 0 &&
                <div className='upcoming-milestone'>
                  <h4 style={{ marginLeft: 12 }}>Upcoming Milestone</h4>
                  <div className='upcoming'>
                    {getUpcomingMilestoneStatusDetails()}
                  </div>
                </div>
              }


              {(["PMO", "customer"].includes(screenType)) ? null :
                <div className='editPencil' onClick={() => handleView("ProjectSchedule")}>
                  <MdModeEditOutline color='orange' />
                </div>
              }
            </div>

          </div>
          <div className='col-sm-7'>
            <div className="row">
              <div className='projectImagesBlock'>
                <div className='' onClick={() => handleView("ProjectImages")}>
                  {console.log("imageDetails----", projectDetails?.imageDetails)}
                  <div className='mainImage' style={{height:"400px"}}>
                    <img src={(projectDetails?.imageDetails?.length ? projectDetails?.imageDetails?.[0]?.dataURL._image_urls : null)} className='bigDummyImage' />
                  </div>
                  <div className='row thumbnailImages' style={{height:"200px"}}>
                    {
                      handleImageDisplay(projectDetails?.imageDetails)?.slice(1).map((value, index) => {
                        console.log("value---", value);
                        if (index < 2) {
                          console.log("index---", index);
                          return (
                            <div className='col'>
                              <img src={value?.dataURL?._image_urls} className='smlDummyImage' />
                            </div>
                          )
                        }
                        else if (index === 2) {
                          return (
                            <div className='col lastImage'>
                              <img src={value.dataURL._image_urls} className='smlDummyImage' />
                              {(projectDetails?.imageDetails || (projectDetails?.imageDetails?.length <= 3)) ? (
                                <>
                                  <div className='overlay' style={{height:"200px"}}></div>
                                  <div className='moreContent'>
                                    {`+${(projectDetails?.imageDetails?.length - 2) - 1} Photos`}
                                  </div>
                                </>
                              )
                                : ""
                              }
                            </div>
                          )
                        }

                      })
                    }
                  </div>
                </div>
                {(!["PM", "PC", "PMO"].includes(screenType)) ? null :
                  <div className='editPencil' onClick={() => handleView("EditProjectDetails")}>
                    <MdModeEditOutline color='orange' />
                  </div>
                }
              </div>
              <div className='row pt-3'>
                <div className='col-6 projectNameInfo'>
                  <div className='projName'>
                    <h3>{projectDetails?.projectName} </h3><span className='projectType g1'>{projectDetails?.projectType}</span>
                  </div>
                  <div className='projDetlLocation'>{projectDetails?.projectLocation},</div>
                  <div className='projDetlCity'>{projectDetails?.projectCity} </div>
                  <div className='projDetPincode'>PinCode :{projectDetails?.projectPincode}</div>

                </div>
                <div className='col-6 map-wrapper' style={{zIndex:10}}>
                  {/* <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "",
                      language: "en",
                      region: "US"
                    }}
                    defaultCenter={
                      {
                        lat: 17.433551884242462,
                        lng: 78.38726417116445
                      }
                    }
                    defaultZoom={17}
                  >
                    <Marker
                      lat="17.434280771516192"
                      lng=" 78.50217711325014"
                      text="samplelocation"
                    />

                  </GoogleMapReact> */}
                  {(projectDetails?.projectLongtitude && projectDetails?.projectLatitude) ? <MapContainer center={[projectDetails?.projectLatitude, projectDetails?.projectLongtitude]} zoom={20} scrollWheelZoom={false}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[projectDetails?.projectLatitude, projectDetails?.projectLongtitude]} icon={iconFunction([projectDetails?.projectLatitude, projectDetails?.projectLongtitude])}>
                      <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </Marker>
                    <Circle center={[projectDetails?.projectLatitude, projectDetails?.projectLongtitude]} pathOptions={{ fillColor: 'blue' }} radius={50} />
                  </MapContainer>
                    : null
                  }
                </div>
              </div>
              <div className='row pt-3 projectPlotAreas'>
                <div className='col'>
                  <h6>Plot Area(Sq.Yd)</h6>
                  <h1> <img src={imageAssets.area1} alt="area1" /> <span>{projectDetails?.plotArea}</span></h1>
                </div>
                <div className='col'>
                  <h6>Slab Area(Sq.Yd)</h6>
                  <h1> <img src={imageAssets.area2} alt="area2" />  <span>{projectDetails?.slabArea}</span></h1>
                </div>

                <div className='col'>
                  <h6>Built Up Area(Sq.Yd)</h6>
                  <h1> <img src={imageAssets.area3} alt="area3" />  <span>{projectDetails?.projectBuildArea}</span></h1>
                </div>
                {
                  projectDetails?.penthouseSlabArea ?
                    <div className='col'>
                      <h6>Penthouse Area(Sq.Yd)</h6>
                      <h1> <img src={imageAssets.area4} alt="area4" /> <span>{projectDetails?.penthouseSlabArea}</span></h1>
                    </div>
                    :
                    null
                }

              </div>

              {/* new codes */}
              {/* {Object.keys(blockedMilestoneDetails).length !== 0 && */}
              {blockedMilestoneDetails.length > 0 &&
                // <>
                <div className='ongoing-milestone' >
                   <h4 
                  style={{ marginLeft: 20, marginBottom: 10,cursor: "pointer",color:"red"}} 
                 
                > 
              
                  Blocked Milestone
                  <BsBoxArrowUpRight style={{marginLeft:5,marginBottom:4,color:"#F8A504",fontSize:"18px",}}onClick={() => handleTabs("BuildingProgress")}/>
                 
                  {(blockedMilestoneDetails.length - 1 > 0) && (
                    <PlusIconWithLength
                      length={blockedMilestoneDetails.length - 1}
                      handler={() => handleTabs("BuildingProgress")} 
                      screenType={screenType}
                    />
                  )}
                </h4>
               
                  {/* {blockedMilestoneDetails.map(item => ( */}
                  <div
                    className={`${blockedMilestoneDetails[0].milestoneStatus === "Blocked"} && milestone-block`}>
                    <Timeline>
                      <Timeline.Item color={blockedMilestoneDetails[0].milestoneStatus === null && "#FF0000" ||
                        blockedMilestoneDetails[0].milestoneStatus === "Completed" && "#52C41A" ||
                        blockedMilestoneDetails[0].milestoneStatus === "Running" && "#3590FF" ||
                        blockedMilestoneDetails[0].milestoneStatus === "pending" && "#E3E4E8"
                      } style={{ marginLeft: "10px" }}>
                        <div className={`projectMileStoneCard`}>
                          <div className='row'>
                            <div className='col-sm-5 milestoneInfo'>
                              <div className=''>
                                <span>{blockedMilestoneDetails[0].milestoneTitle}</span>
                                <span>{["Unblock Requested", "Blocked"].includes(blockedMilestoneDetails[0].milestoneStatus) && "Blocked"}</span>
                                <div><span>{blockedMilestoneDetails[0].milestoneStatusPercentage || 0}%</span> Completed</div>
                                <Progress size="small" percent={blockedMilestoneDetails[0].milestoneStatusPercentage || 0} />
                              </div>
                            </div>
                            <div className='col-sm-3 milestoneNum'>
                              <div className=''>
                                <span>Milestone</span>
                                <span>{blockedMilestoneDetails[0].milestoneId}</span>
                              </div>
                            </div>
                            <div className='col-sm-4 milestoneEtc'>
                              <div className=''>
                                <span>ETC</span>
                                <div className='milestoneDate'>
                                  <span>{blockedMilestoneDetails[0].milestoneEtc ? moment(blockedMilestoneDetails[0].milestoneEtc).format('D') : 'N/A'}</span>
                                  <div>
                                    <span>{blockedMilestoneDetails[0].milestoneEtc && moment(blockedMilestoneDetails[0].milestoneEtc).format('MMM')}</span>
                                    <span>{blockedMilestoneDetails[0].milestoneEtc && moment(blockedMilestoneDetails[0].milestoneEtc).format('YYYY')}</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </Timeline.Item>

                    </Timeline>

                  </div>
                </div>


                // </>
              }
              {/* end----- */}
            </div>

          </div>
        </div>
      </div>
      {pmcontextHolder}
      {pccontextHolder}

      <div className='row allocatedDetails mb-3'>
        {
          projectDetails?.allocationdetails?.map((values, index) => {
            console.log("allocation details --->", values);
            return ((values?.name!==null && values?.type === "Project Manager" && screenType==="PMO") ? ( (
                <Card className='col'>
                  <div className='roundedIcons'>
                    
                    {/* <Avatar size={"large"} icon={<BsPerson size={20} />} /> */}
                    <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/Images/Profile-Image/4e377326-4abb-11ed-b0c5-0a45a85dffcc/884c1ee0-696e-4fc4-a407-28295b233705.jpg" alt="" class="blankAvatarImg"></img>
                    
                    <div>
                      <h4>{newPM}</h4>
                      <span>{values?.type}</span>
                    </div>
                    {console.log(localStorage.getItem("user_id"),"userrrrrrrrrrrrid")}
                   
                    <div className='ms-auto mb-auto'>
                    <MdOutlineEdit className='editPM' style={{backgroundColor:"#F4F5F9",padding:"3px",borderRadius:"8px",fontSize:"20px"}} onClick={()=>{setViewPMModal(true)
                    
                    }}></MdOutlineEdit>
                    </div>
                  </div>
                  
                </Card>


              )) : (values?.name!==null && values?.type === "Project Coordinator"  &&  screenType==="PMO") ? ( (
                <Card className='col'>
                  <div className='roundedIcons'>
                    
                    {/* <Avatar size={"large"} icon={<BsPerson size={20} />} /> */}
                    <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/Images/Profile-Image/4e377326-4abb-11ed-b0c5-0a45a85dffcc/884c1ee0-696e-4fc4-a407-28295b233705.jpg" alt="" class="blankAvatarImg"></img>
                    
                    <div>
                      <h4>{newPC}</h4>
                      <span>{values?.type}</span>
                    </div>
                    <div className='ms-auto mb-auto'>
                    <MdOutlineEdit className='editPM' style={{backgroundColor:"#F4F5F9",padding:"3px",borderRadius:"8px",fontSize:"20px"}} onClick={()=>{setViewPCModal(true)}}></MdOutlineEdit>
                    </div> 
                  </div>
                  
                </Card>


              )) : (
              values?.name!=="null" &&
              <Card className='col'>
                <div className='roundedIcons'>
                  
                  {/* <Avatar size={"large"} icon={<BsPerson size={20} />} /> */}
                  <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/Images/Profile-Image/4e377326-4abb-11ed-b0c5-0a45a85dffcc/884c1ee0-696e-4fc4-a407-28295b233705.jpg" alt="" class="blankAvatarImg"></img>
                  
                  <div>
                    <h4>{values?.name}</h4>
                    <span>{values?.type}</span>
                  </div>
                  <div className='ms-auto mb-auto'>
                  </div>
                </div>
                
              </Card>)
            )
          })
        }
      </div>
                {/* Modal for Edit PM */}
                {/* {console.log("OtherPMS: ", allPMs)} */}
      <Modal        
        visible={viewPMModal}
        onOk={() => setViewPMModal(false)}
        onCancel={()  => {
          setViewPMModal(false);
           setLogHistory(false)
        }}
          
        
        onClose={() => {
          setViewPMModal(false);
           setLogHistory(false)
        }
       }
        closable={true}  
        title={logHistory ? 
          (
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                  <button className="btn p-0" onClick={handleLogView}><span style={{fontSize:"14px", fontWeight:'bold'}}><AiOutlineArrowLeft />Back</span></button>
          
                </div>
        ) : (
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                  <span style={{fontSize:"14px",fontWeight:"bold"}}>Project Manager</span>
                  
                  <button className='btn btn-outline-secondary'  style={{fontSize:"12px", marginRight:"20px",fontWeight:"normal",border: '1px solid #ced4da',backgroundColor:"#f8f9fa",borderRadius:"4px",boxShadow:"none",color:"#495057",cursor:"pointer"}}onClick={handleLogView}>Reallocate Log History</button>
              </div>
        )}
                  width={500}
                  bodyStyle={{ overflowY: 'auto', maxHeight: '500px' }}
                  style={{height:600+"px"}}
                  okText="Reallocate"
                  cancelText="Cancel"
                  
                  footer={[
                    <Button className='cancelBtnR' onClick={()=>{setViewPMModal(false)}}>
                      Cancel
                    </Button>,
                    <Button key="submit" className='reallocateBtn' onClick={()=>{setViewPMModal(false); assignNewPM();}}> 
                      Reallocate
                    </Button>,
        ]}
      >
        {!logHistory ? (<div style={{height:400+"px"}}>
          <div>
            <div className='logBackground'>
              <span className='allocatedDesignation'>Allocated to Project Manager - <span className='allocatedName'>{pendingPMLogs.pm_name}</span></span>
              {/* <span className='allocatedLogDate'>{new Date(pendingPMLogs.start_date).getUTCDate() + " - " + (new Date(pendingPMLogs.start_date).toLocaleString('default', {month: "short"})) +" "+ new Date(pendingPMLogs.start_date).getUTCFullYear() + " - " + "Till"}</span> */}
            </div>
          </div>
          <span>Select Project Manager to Reallocate</span>
        <Select defaultValue="Select"
          style={{width:100+"%"}}
          onChange={handlePMChange}
        >
          {projectDetails?.otherPMs?.map((val, ind) => (
              <Option key={ind} value={JSON.stringify(val)}>{val?.firstname}</Option>
            ))}
          
                </Select>
                </div>
        ) : 
        (
          <div style={{height:400+"px",overflow:"auto"}}>
            {/* <h6 style={{fontWeight:"normal"}}>Log History </h6> */}
            <div>
                  <div className='logBackground' style={{paddingRight:"2px"}}>
                    <span className='allocatedDesignation'>Project Manager - <span className='allocatedName'>{pendingPMLogs.pm_name}</span></span>
                    <span className='allocatedLogDate'>{new Date(pendingPMLogs.start_date).getUTCDate() + " - " + (new Date(pendingPMLogs.start_date).toLocaleString('default', {month: "short"})) +" "+ new Date(pendingPMLogs.start_date).getUTCFullYear() + " - " + "Till"}</span>
                  </div>
                </div>
          {pmLogs?.map((val, ind)=> { 
              return (
                <div key={ind}>
                  <div className='logBackground' style={{paddingRight:"2px"}}>
                    <span className='allocatedDesignation'>Project Manager - <span className='allocatedName'>{val.pm_name}</span></span>
                    <span className='allocatedLogDate'>{new Date(val.start_date).getUTCDate() + " - " + (new Date(val.start_date).toLocaleString('default', {month: "short"})) +" "+ new Date(val.start_date).getUTCFullYear() + " - "+new Date(val.end_date).getUTCDate() + " - " + (new Date(val.end_date).toLocaleString('default', {month: "short"})) +" "+ new Date(val.end_date).getUTCFullYear()}</span>
                  </div>
                </div>
              )
            } )
          }
          </div>
        )}
        {/* <Button onClick={()=>{setViewPMModal(false)}} className="ms-auto">Cancel</Button>
        <Button onClick={()=>{setViewPMModal(false)}}>Reallocate</Button> */}
      </Modal>
                
                 {/* Modal for Edit PC */}
      <Modal        
        visible={viewPCModal}
        onOk={()=>setViewPCModal(false)}
        onCancel={() => {
          setViewPCModal(false);
           setLogHistory(false)
        }}
        onClose={()=>{
          setViewPCModal(false)
           setLogHistory(false)
        }
          
        }
        closable={true}
        
        title={logHistory ? (

                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                  <button className="btn p-0" onClick={handleLogView} style={{alignItems:"center"}}>

                    <span style={{fontSize:"14px", fontWeight:'bold'}}><AiOutlineArrowLeft />Back</span>
                    

                    </button>
            
              </div>
      ) : (
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                  <span style={{fontSize:"14px",fontWeight:"bold"}}>Project Coordinator</span>

                  <button className='btn btn-outline-secondary' style={{fontSize:"12px", marginRight:"20px ",backgroundColor:"#f8f9fa",fontWeight:"normal", border: '1px solid #ced4da',borderRadius:"4px",boxShadow:"none",color:"#495057",cursor:"pointer"}}onClick={handleLogView}>Reallocate Log History</button>
                </div>
          )}

              width={500}
              bodyStyle={{ overflowY: 'auto', maxHeight: '500px' }}

              style={{height:600+"px"}}
              okText="Reallocate"
              cancelText="Cancel"
                  footer={[
                    <Button className='cancelBtnR' onClick={()=>{setViewPCModal(false)}}>
                      Cancel
                    </Button>,
                    <Button key="submit" className='reallocateBtn' onClick={()=>{setViewPCModal(false); assignNewPC();}}  >
                      Reallocate
                    </Button>,
        ]}
      >
        {!logHistory ? (<div style={{height:400+"px"}}>
          <div>
            <div className='logBackground'>
              <span className='allocatedDesignation'>Allocated to Project Coordinator - <span className='allocatedName'>{pendingPCLogs.pc_name}</span></span>
              {/* <span className='allocatedLogDate'>{new Date(pendingPMLogs.start_date).getUTCDate() + " - " + (new Date(pendingPMLogs.start_date).toLocaleString('default', {month: "short"})) +" "+ new Date(pendingPMLogs.start_date).getUTCFullYear() + " - " + "Till"}</span> */}
            </div>
          </div>
          <span>Select Project Coordinator to Reallocate</span>
        <Select defaultValue="Select"
          style={{width:100+"%"}}
          onChange={handlePCChange}
        >
          {projectDetails?.otherPCs?.map((val, ind) => (
              <Option key={ind} value={JSON.stringify(val)}>{val?.firstname}</Option>
            ))}
          
        </Select>
        </div>) : (<div style={{height:400+"px",overflow:"auto"}}>
          {/* <span>Log History </span> */}
                <div>
                  <div className='logBackground'style={{paddingRight:"2px"}}>
                    <span className='allocatedDesignation'>Project Coordinator - <span className='allocatedName'>{pendingPCLogs.pc_name}</span></span>
                    <span className='allocatedLogDate'>{new Date(pendingPCLogs.start_date).getUTCDate() + " - " + (new Date(pendingPCLogs.start_date).toLocaleString('default', {month: "short"})) +" "+ new Date(pendingPCLogs.start_date).getUTCFullYear() + " - " + "Till"}</span>
                  </div>
                </div>
          {pcLogs?.map((val, ind)=> { 
              return (
                <div key={ind}>
                  <div className='logBackground' style={{paddingRight:"2px"}}>
                    <span className='allocatedDesignation'>Project Coordinator - <span className='allocatedName'>{val.pc_name}</span></span>
                    <span className='allocatedLogDate'>{new Date(val.start_date).getUTCDate() + " - " + (new Date(val.start_date).toLocaleString('default', {month: "short"})) +" "+ new Date(val.start_date).getUTCFullYear() + " - "+new Date(val.end_date).getUTCDate() + " - " + (new Date(val.end_date).toLocaleString('default', {month: "short"})) +" "+ new Date(val.end_date).getUTCFullYear()}</span>
                  </div>
                </div>
              )
            } )
          }
                
              
                </div>
        )}
        {/* <Button onClick={()=>{setViewPMModal(false)}} className="ms-auto">Cancel</Button>
        <Button onClick={()=>{setViewPMModal(false)}}>Reallocate</Button> */}
      </Modal>  


      <Modal
        visible={viewModal}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        className={`customModal ${typeOfComponent === "ProjectImages" ? "imgCarousel" : ""}`}
        title={handleTitle(typeOfComponent)}
      >
        {
          COMPONENTSENUM[typeOfComponent]
        }
      </Modal>
    </div>


  )
}

export default ProjectDetails;