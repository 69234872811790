import moment from 'moment';
import React from 'react';
import '../ProjectDetails.css'

const logContentWrapper = (
    {
        logDetails
    }
) => {

    return (
        <div
        //  className='logContentWrapper'
         style={{padding:"0px"}}
         >
            {/* <div className='imgWrapper'>
                <img src="https://wehouse-images.s3.ap-south-1.amazonaws.com/Images/Profile-Image/4e377326-4abb-11ed-b0c5-0a45a85dffcc/884c1ee0-696e-4fc4-a407-28295b233705.jpg"
                    alt="" class="blankAvatarImg"
                />

            </div> */}
          <div className='contentWrapper' >
  <div
    style={{
      paddingRight: "2px",
      backgroundColor: "#fff8eb",
      height: "auto", // Adjust height for multi-line content
      borderRadius: "5px",
      width:"100%",
      border: "2px dashed #FDE9C3",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column", // Stack content vertically
      alignItems: "flex-start", // Align text to start
      marginBottom: "6px",
      padding: "4px", // Add padding for spacing
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <span className='allocatedName'>
        {logDetails?.changedName}
        <span className="allocatedDesignation" >
         ( {logDetails?.designation})
       
      </span>
      
        </span>
      <span className='allocatedLogDate'>
        {moment(logDetails?.logTime).utc().format("DD MMM YYYY , hh:mm A")}
      </span>
    </div>
    
   
    <p style={{fontWeight:"bold"}}>Log Description :
    <span style={{fontWeight:"normal"}}>{logDetails?.description}</span>
        
        </p>
  </div>
</div>

        </div>
    )
}

export default logContentWrapper