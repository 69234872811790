import React from 'react';
import ReactDOM from 'react-dom';
import 'rc-pagination/assets/index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import { Provider } from 'react-redux';
import store from './Store/Store';

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import appSyncConfig from '../src/Service/RightChatEnginePanel/aws-exports';


const client = new ApolloClient({
  uri: appSyncConfig.aws_appsync_graphqlEndpoint, 
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': appSyncConfig.aws_appsync_apiKey // Add authentication if required
  },
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

