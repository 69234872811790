import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ArrowBack, CheckCircle, Close } from '@mui/icons-material';
import { useStyles } from './AddUserToRoom.style';
import { Checkbox, IconButton, InputBase, Chip } from '@mui/material';

interface Contact {
  account_id: string;
  user_id: string;
  user_details: any;
}

interface AddMemberPopupProps {
  isOpen: boolean;
  onClose: () => void;
  contacts: any;
  onSelectMembers: (selectedIds: string[]) => void;
  roomDetails:any
}

const AddUserToRoom: React.FC<AddMemberPopupProps> = ({
  isOpen,
  onClose,
  contacts,
  onSelectMembers,
  roomDetails
}) => {

 
  const existingUsersDetails=roomDetails?.getRoomDetails?.associatedUsers
  console.log(existingUsersDetails,"existing users in room")
  const { classes } = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const handleContentClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const handleToggleContact = (contact: any) => {
    setSelectedContacts((prev) =>
      prev.find((c) => c.user_id === contact.user_id)
        ? prev.filter((c) => c.user_id !== contact.user_id)
        : [...prev, contact]
    );
  };

  const handleRemoveSelected = (contactToRemove: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedContacts((prev) =>
      prev.filter((contact) => contact.user_id !== contactToRemove.user_id)
    );
  };

  const handleSubmit = () => {
    onSelectMembers(selectedContacts.map((contact) => contact.user_id));
    setSelectedContacts([])
  };

  console.log('contacts in user rooms', contacts);

  const filteredContacts = contacts.filter(
    (contact: any) =>
      contact?.user_details?.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.user_details?.user_name.includes(searchQuery)
  );
  const logginedUserDetails = useSelector((state: any) => state.ChatServiceReduxSlice.userDetailsInRedux);

  console.log(logginedUserDetails,"user details")
  console.log('contacts in user rooms filteredContacts', filteredContacts);

  if (!isOpen) return null;

  return (
    <div className={classes.overlay} onClick={handleClose} >
      <div className={classes.addUserModal} onClick={handleContentClick}>
        <div className={classes.header} style={{backgroundColor:"#F8A504"}}>
          <IconButton size="small" onClick={handleClose} sx={{ color: '#fff' }}>
            <Close />
          </IconButton>
          <span className={classes.headerTitle} >Add member</span>
        </div>

        <ul className={classes.selectedArea}>
          {selectedContacts.length > 0 && (
            <div className={classes.selectedChips}>
              {selectedContacts.map((contact) => (
                <Chip
                  key={contact.user_id}
                  label={contact.user_details.user_name}
                  onDelete={(e) => handleRemoveSelected(contact, e as any)}
                  avatar={
                    <div className={classes.chipAvatar}>{contact.user_details.user_name[0]}</div>
                  }
                  className={classes.chip}
                />
              ))}
            </div>
          )}
        </ul>

        <div className={classes.searchBar}>
          <IconButton className={classes.backButton} onClick={handleClose}>
            <ArrowBack />
          </IconButton>
          <div className={classes.searchInput} >
            <InputBase
              placeholder="Search name or number"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
          
             
              fullWidth
              sx={{
                border: 'none',
                outline: 'none',
                '& .MuiInputBase-input': {
                  border: 'none',
                  outline: 'none',
                },
              }}
            />
            <div className={classes.searchDot} />
          </div>
        </div>

        <div className={classes.contactsHeader} style={{fontWeight:"bold"}}>CONTACTS</div>

        <div className={classes.contactsList}>
        {filteredContacts.map((contact: any) => {
  // Check if the contact already exists in existingUsersDetails
  const userExists = existingUsersDetails.some(
    (eachUser) => eachUser?.user_details?.user_id === contact?.user_id
  );

  return (
    <label key={contact.id} className={classes.contactItem}>
  
      {!userExists && (
        <Checkbox
          checked={selectedContacts.some((c) => c.user_id === contact.user_id)}
          onChange={() => handleToggleContact(contact)}
          className={classes.checkbox}
        />
      )}

      {/* Avatar Section */}
      {contact.avatar ? (
        <img
          src={contact.avatar}
          alt={contact.user_details.user_name}
          className={classes.avatar}
        />
      ) : (
        <div className={classes.avatar}>{contact.user_details.user_name[0]}</div>
      )}

      {/* Contact Info Section */}
      <div className={classes.contactInfo}>
        <div className={classes.contactName}>{contact.user_details.user_name}</div>

 
        {userExists && <div>user already exists in group</div>}
      </div>
    </label>
  );
})}

        {filteredContacts.length===0  && 
        <div style={{

          textAlign:"center",
         
        }} >
        <h3 style={{color:"red",fontSize:"24px"}}>No Contacts Found</h3>

          </div>

        }

        </div>

        {selectedContacts.length > 0 && (
          <div className={classes.footer}>
            {/* <p className={classes.footerText}>
              All members are able to add others to this group.{' '}
              <span className={classes.footerLink}>Edit group permissions</span>
            </p> */}
            <IconButton className={classes.submitButton} onClick={handleSubmit}>
              {/* <ArrowBack className={classes.submitIcon} /> */}
              <CheckCircle
                sx={{
                  color: '#F8A504', // or your preferred green color
                  fontSize: 48 // adjust size as needed
                }}
              />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddUserToRoom;
