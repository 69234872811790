import { useState, useEffect } from 'react';
import { useStyles } from './CreateGroupOrUser.style';
import { CreateGroupOrUserProps } from './CreateGroupOrUser.type';
import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GET_ALL_USERS } from '../../queries/getAllUsers';
import { CREATE_GROUP_WITH_USERS } from '../../queries/CreateGroupOrUser';
import Chip from '@mui/material/Chip';

import { toast } from 'react-toastify';

interface User {
  user_id: string;
  user_name: string;
  email_id: any;
}

const CreateGroupOrUser: React.FC<CreateGroupOrUserProps> = ({
  isOpen,
  onClose,
  isExpandChat,
  userId,
  refetchRooms
}) => {
  console.log(isOpen,isExpandChat,userId,refetchRooms,"propsss")
  const [groupName, setGroupName] = useState<string>('');
  const [groupType, setGroupType] = useState<'user' | 'group'>('user');
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const theme = useTheme();

  const { data: getAllUsers } = useQuery(GET_ALL_USERS);
  console.log('getAllUsers', getAllUsers);
  const [createGroupWithUsers] = useMutation(CREATE_GROUP_WITH_USERS);
console.log(isExpandChat)
  const { classes } = useStyles();
  useEffect(() => {
    if (getAllUsers && getAllUsers.getAllUsers) {
      console.log("Fetched Users:", getAllUsers.getAllUsers);
      setUsers(getAllUsers.getAllUsers);
    }
  }, [getAllUsers]);
  console.log("Filtered Users:", users.filter((user) => user.user_id !== userId));


  // const handleUserSelection = (id: string) => {
  //   setSelectedUsers((prevSelected) =>
  //     prevSelected.includes(id)
  //       ? prevSelected.filter((userId) => userId !== id)
  //       : [...prevSelected, id]
  //   );
  // };

  const handleSubmit = async () => {
    console.log('Group Name:', groupName);
    console.log('Group Type:', groupType);
    console.log('Selected Users:', selectedUsers);
    const userIds = personName
      .map((name) => users.find((user) => user.user_name === name)?.user_id)
      .filter(Boolean);
      if (!userIds.includes(userId)) {
        userIds.push(userId);
      }
    let createGroup = {
      group_name: groupName,
      room_type: 'group',
      user_ids: userIds,
      created_by_user_id: userId
    };
    console.log('createGroup', createGroup);
    try {
      const response = await createGroupWithUsers({ variables: { input : createGroup} });
      if (response) {
        console.log('Group Created Successfully');
        toast('Group Created Successfully');
        refetchRooms();
    
      }
    } catch (err:any) {
      console.error('Error creating Group:', err.message);
    }

    // Reset state and close popup
    setPersonName([]);
    setGroupName('');
    setGroupType('user');
    setSelectedUsers([]);
    onClose();
  };

  console.log('personName', personName);
  if (!isOpen) return null;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
        width: 250,
        zIndex: 1500
      }
    },
    disablePortal: false
  };

  // const handleDelete = (valueToRemove: any) => {
  //   setPersonName((prev) => prev.filter((value) => value !== valueToRemove));
  // };

  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight: personName.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular
    };
    
  }

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event;
    console.log('event', event);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div className='chatPopup'>
    <div className={classes.overlay}>
      <div className={classes.popup}>
        <div className={classes.CloseButtonContainer}>
          {/* <label htmlFor="groupName">Group Name:</label> */}
          <h2 style={{ fontSize: '20px' }}>Create Group</h2>
          <div onClick={onClose} className={classes.closeButton}></div>
        </div>

        {/* Group Name Input */}
        <div className={classes.field}
          style={{border:"0px",borderColor:"white"}}
        >
          <label htmlFor="groupName" className={classes.groupName}>
            Group Name:
          </label>

          <input
            type="text"
            id="groupName"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className={classes.input}
          
          />
        </div>

        {/* User Selection */}
        <div className={classes.field}>
          <label className={classes.groupName}>Select Users:</label>
          <div>
            <FormControl sx={{ width: '100%', mt: 1 }}>
              <InputLabel id="demo-multiple-chip-label">users</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                      maxHeight: '50px',
                      overflowY: 'auto'
                    }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}>
                {users
                  .filter((user) => user.user_id !== userId) // Filter out the logged-in user

                  .map((user) => (
         
                    <MenuItem
                      key={user.user_id}
                      value={user.user_name}
                      style={getStyles(user.user_name, personName, theme)}>
                      {user.user_name}
                    </MenuItem>
                    
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {/* Group Type Selector */}
        {/* <div className={classes.field}>
          <label className={classes.groupName}>Group Type:</label>
          <div>
            <label className={classes.groupName}>
              <input
                type="radio"
                name="groupType"
                value="user"
                checked={groupType === 'user'}
                onChange={() => setGroupType('user')}
              />
              User Group
            </label>
            <label style={{ marginLeft: '20px' }} className={classes.groupName}>
              <input
                type="radio"
                name="groupType"
                value="group"
                checked={groupType === 'group'}
                onChange={() => setGroupType('group')}
              />
              General Group
            </label>
          </div>
        </div> */}

        {/* Submit Button */}
        <div className={classes.actions}>
          <button onClick={handleSubmit} className={classes.submitButton} style={{backgroundColor:"#F8A504"}}>
            Submit
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CreateGroupOrUser;
