import { el } from "date-fns/locale";

const handleSocketData = (socketData, setNotifications) => {
  console.log(socketData, "socketData");

  const userId = Number(window.localStorage.getItem("user_Id"));
  console.log(userId, "userId");

  if (socketData?.recipient_ids?.includes(userId)) {
    console.log("New notification received via socket", socketData);

    let newNotification = {};

    if (socketData.entity_type_id === 2) {
      // **Project Allocation Notification**
      newNotification = {
        title: `Project ${socketData.project_name}`,
        description: `Allocated to ${socketData.pmo_name}, ${socketData.pm_name}, ${socketData.pc_name}, and ${socketData.customer_name}.`,
        budget: `Budget: ${socketData.budget}`,
        timeline: `Timeline: ${socketData.start_date} - ${socketData.end_date}`,
      };
    } else if (socketData.entity_type_id === 3) {
      // **Task Status Notification**
      if (socketData._task_status_name === "In Progress") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `In-progress of SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is Approved by ${socketData._actor_name}`,
        };
      } else if (socketData._task_status_name === "In-progress Requested") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} is in In-progress.`,
        };
       } else if(socketData._task_status_name === "In Progress - Approved by PM") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `In-progress Request of SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is Approved by ${socketData.pm_name}.`,
        };
       }
      
      else if(socketData._task_status_name === "Completed Requested") {
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `${socketData._task_status_name} by ${socketData.pc_name} for SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name}`,
        };
      } else if(socketData._task_status_name === "Completed") {
        let status = socketData._task_status_name;
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: ` SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is ${status}`,
        };
      } else if(socketData._task_status_name === "Completed - Approved by PM"){
        newNotification = {
          title: `Project ${socketData.project_name}`,
          description: `Completed Request of SubTask ${socketData._task_type_name} of milestone ${socketData._milestone_name} in Project ${socketData.project_name} is Approved by ${socketData.pm_name}`,
        };
      }
    } else if(socketData?.entity_type_id === 6) {
      if(socketData?._task_action_status === "Ticket Created"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket is Created for Subtask ${socketData?.task_type_name} in Milestone ${socketData?.milestone_name} With  Impact of days-${socketData?.Impact_days}.`
        }
      }else if(socketData?._task_action_status ==="Ticket Raised Approved"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket Raised for ${socketData.task_type_name} in ${socketData.milestone_name} has been Approved by PM ${socketData.pm_name}.`
        }
      } else if(socketData?._task_action_status ==="Inprogress Request"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket for ${socketData.task_type_name} in ${socketData.milestone_name} is in Inprogress.`
        }
      }else if(socketData?._task_action_status ==="Inprogress"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Inprogress Ticket of ${socketData.task_type_name} in ${socketData.milestone_name}  has been Approved by PM ${socketData.pm_name}.`
        }
      }else if(socketData?._task_action_status ==="Completed Requested"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket Completed Request by PC ${socketData.pc_name} for ${socketData.task_type_name} in ${socketData.milestone_name}.`
        }
      }else if(socketData?._task_action_status ==="Completed"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket for ${socketData.task_type_name} in ${socketData.milestone_name} has been marked as Completed by PM ${socketData.pm_name}.`
        }
      }else if(socketData?._task_action_status ==="Ticket Raised Rejected"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket Raised for ${socketData.task_type_name} in ${socketData.milestone_name} has been Rejected by PM ${socketData.pm_name}.`
        }
      } else if(socketData?._task_action_status ==="Ticket ReOpen"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Ticket for ${socketData.task_type_name} in ${socketData.milestone_name} is reopened by PC ${socketData.pc_name}.`
        }
      }else if(socketData?._task_action_status ==="Ticket ReOpen Rejected"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Reopened Ticket for ${socketData.task_type_name} in ${socketData.milestone_name} has been Rejected by PM ${socketData.pm_name}.`
        }
      } else if(socketData?._task_action_status ==="Ticket ReOpen Approved"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description: `Reopened Ticket for ${socketData.task_type_name} in ${socketData.milestone_name} has been Approved by PM ${socketData.pm_name}.`
        }
      }else if(socketData?._task_action_status ==="Completed Requested Rejected"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description: `Completed Request for ${socketData.task_type_name} in ${socketData.milestone_name} has been Rejected by PM ${socketData.pm_name}.`
        }
      }
     } else if(socketData?.entity_type_id === 8) {
      newNotification={
        title:`Project ${socketData?.project_name}`,
        description: `${socketData.action_content} a new drawing ${socketData.task_description} for ${socketData.task_type_name} in ${socketData.milestone_name}.`
      }
     } else if(socketData?.entity_type_id === 9) {
      if(socketData.action_content === "PM Approved") {
      newNotification={
        title:`Project ${socketData?.project_name}`,
        description: `PM ${socketData.pm_name} has approved the drawing ${socketData.task_description} for ${socketData.task_type_name} in ${socketData.milestone_name}.`
      }
    } else if(socketData.action_content === "Customer Approved") {
      newNotification={
        title:`Project ${socketData?.project_name}`,
        description: `Customer ${socketData.customer_name} has approved the drawing ${socketData.task_description} for ${socketData.task_type_name} in ${socketData.milestone_name}.`
      }
    } else if(socketData.action_content === "PM Rejected") {
      newNotification={
        title:`Project ${socketData?.project_name}`,
        description: `PM ${socketData.pm_name} has rejected the drawing ${socketData.task_description} for ${socketData.task_type_name} in ${socketData.milestone_name}.`
      }
     } else if(socketData.action_content === "Customer Rejected") {
      newNotification={
        title:`Project ${socketData?.project_name}`,
        description: `Customer ${socketData.customer_name} has rejected the drawing ${socketData.task_description} for ${socketData.task_type_name} in ${socketData.milestone_name}.`
      } 
    }
    }else if(socketData?.entity_type_id === 7) {
      if(socketData._task_action_status === "Block Requested"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`${socketData.task_type_name} in ${socketData.milestone_name} has been ${socketData._task_action_status.toLowerCase()} by ${socketData.pc_name}.`
        }
      }else if(socketData._task_action_status === "Blocked by PM"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`${socketData.task_type_name} in ${socketData.milestone_name} has been ${socketData._task_action_status.toLowerCase()} ${socketData.pm_name}.`
        }
      }else if(socketData._task_action_status === "Block Approved"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`${socketData.task_type_name} in ${socketData.milestone_name} has been ${socketData._task_action_status.toLowerCase()} by ${socketData.pm_name}.`
        }
      }else if(socketData._task_action_status === "Unblock Requested"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`${socketData.task_type_name} in ${socketData.milestone_name} has been ${socketData._task_action_status.toLowerCase()} by ${socketData.pc_name}.`
        }
      }else if(socketData._task_action_status === "Unblock Approved"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`${socketData.task_type_name} in ${socketData.milestone_name} has been ${socketData._task_action_status.toLowerCase()} by ${socketData.pm_name}.`
        }
      }else if(socketData._task_action_status === "Unblocked by PM"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`${socketData.task_type_name} in ${socketData.milestone_name} has been ${socketData._task_action_status.toLowerCase()}  ${socketData.pm_name}.`
        }
      }
    }else if(socketData?.entity_type_id === 15) {
      if(socketData.entity_type_name === "reallocate project to pm"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Project ${socketData.project_name} has been Reallocated to PM ${socketData._new_pm_name}.`
        }
      }
    }else if(socketData?.entity_type_id === 10) {
      if(socketData.entity_type_name === "reallocate project to pc"){
        newNotification={
          title:`Project ${socketData?.project_name}`,
          description:`Project ${socketData.project_name} has been Reallocated to PC ${socketData._new_pc_name}.`
        }
      }
    }else if(socketData?.entity === "Sales"&& socketData.Lead_count) {
      newNotification={
        title:"Leads Upload",
      description: `🚀 ${socketData.Lead_count} new leads added to the system!`
      }
    }else if(socketData?.entity_type === "Status Change"){
      const { receiver_name, lead_name, present_status, _actor_name, entity_type, previous_status }= socketData;
      if(previous_status){
      newNotification={
        title:"Leads Status",
      description: `Hi ${receiver_name}, lead ${lead_name} status changed from ${previous_status} to ${present_status} by ${_actor_name}`
      }
    }else{
      newNotification={
        title:"Leads Status",
      description: `Hi ${receiver_name}, lead ${lead_name} status changed to ${present_status} by ${_actor_name}`
      }
    }
    }
    if (Object.keys(newNotification).length > 0) {
      newNotification = {
        ...newNotification,
        entityTypeId: socketData.entity_type_id,
        notificationId: socketData.notification_id, 
        read: socketData?.isRead,
      };
      console.log(newNotification, "New Ticket Notification");  // Debugging log
      setNotifications((prev) => {
        console.log(prev, "Previous Notifications List");
        
        const isDuplicate = prev.some(
          (notif) => 
            notif.notificationId === newNotification.notificationId || 
            notif.description === newNotification.description
        );
        
        if (!isDuplicate) {
          return [newNotification, ...prev];
        }
        return prev;
      });

    
    }
  }
};

export default handleSocketData;
