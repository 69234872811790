import React from 'react'
import { useHistory,Link } from 'react-router-dom';
import { AiOutlinePoweroff } from 'react-icons/ai';

function Signout() {
    const history = useHistory()
    
    const landingBtn = () => {
        // window.localStorage.clear();
        window.localStorage.setItem('isErrMsg',false);
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user_Id');
        window.localStorage.removeItem('userName');

        history.replace("/signin")
        
    }
    return (
        // <div className="off-icon-layout" onClick={landingBtn} style={{cursor:"pointer"}}>
        //     {/* <AiOutlinePoweroff className="off-icon" /> */}
        //     {/* <Link to="/signin" >SignOut</Link>
        //      */}
        //      <span style={{color:"#267FE2"}}>SignOut</span>
        // </div>
        <span style={{color:"#267FE2", cursor:"pointer"}} onClick={landingBtn}>SignOut</span>
    )
}

export default Signout;